import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  sendingSessionId: false,
  sessionIdInfo: false,
  sendingRecordsToChatbot: false,
  chatbotRecords: false,
  error: null,
};

const chatbotSessionIdRequest = (state = INITIAL_STATE) => {
  return { 
    ...state, 
    sendingSessionId: true, 
    sessionIdInfo: false,
    error: null 
  };
};

const chatbotSessionIdSuccess = (state = INITIAL_STATE, { sessionIdInfo }) => {
  return {
    ...state,
    sessionIdInfo,
    sendingSessionId: false,
  };
};

const chatbotSessionIdFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    sendingSessionId: false,
    sessionIdInfo: false,
    error,
  };
};

const updateChatbotRecordRequest = (state = INITIAL_STATE) => {
  return {
    ...state, 
    sendingRecordsToChatbot: true,
    chatbotRecords: false,
    error: null
  };
};

const updateChatbotRecordSuccess = (state = INITIAL_STATE, { chatbotRecords }) => {
  return {
    ...state,
    chatbotRecords,
    sendingRecordsToChatbot: false,
  };
};

const updateChatbotRecordFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    sendingRecordsToChatbot: false,
    chatbotRecords: false,
    error,
  };
};

export const HANDLERS = {

  [Types.CHATBOT_SESSION_ID_REQUEST]: chatbotSessionIdRequest,
  [Types.CHATBOT_SESSION_ID_SUCCESS]: chatbotSessionIdSuccess,
  [Types.CHATBOT_SESSION_ID_FAILURE]: chatbotSessionIdFailure,

  [Types.UPDATE_CHATBOT_RECORD_REQUEST]: updateChatbotRecordRequest,
  [Types.UPDATE_CHATBOT_RECORD_SUCCESS]: updateChatbotRecordSuccess,
  [Types.UPDATE_CHATBOT_RECORD_FAILURE]: updateChatbotRecordFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
