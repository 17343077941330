import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  hmac: '',
  accessToken: {},
  geoLocationCoordinates: {},
  error: null,
};

const request = (state = INITIAL_STATE) => {
  return { ...state, isRequesting: true, error: null };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const getGeoLocationSuccess = (state = INITIAL_STATE, { geoLocationCoordinates }) => {
  return {
    ...state,
    geoLocationCoordinates,
    isRequesting: false,
  };
};

const generateHashSuccess = (state = INITIAL_STATE, { hmac }) => {
  return {
    ...state,
    hmac,
    isRequesting: false,
  };
};

const generateAccessToken = (state = INITIAL_STATE, { accessToken }) => {
  return {
    ...state,
    accessToken,
    isRequesting: false,
  };
};

export const HANDLERS = {
         [Types.GENERATE_HASH_REQUEST]: request,
         [Types.GENERATE_HASH_SUCCESS]: generateHashSuccess,
         [Types.GENERATE_HASH_FAILURE]: failure,

         [Types.GENERATE_ACCESS_TOKEN_REQUEST]: request,
         [Types.GENERATE_ACCESS_TOKEN_SUCCESS]: generateAccessToken,
         [Types.GENERATE_ACCESS_TOKEN_FAILURE]: failure,

         [Types.GET_GEO_LOCATION_REQUEST]: request,
         [Types.GET_GEO_LOCATION_SUCCESS]: getGeoLocationSuccess,
         [Types.GET_GEO_LOCATION_FAILURE]: failure,
       };

export default createReducer(INITIAL_STATE, HANDLERS);
