import { takeLatest, call, put } from 'redux-saga/effects';
import api from 'services/api';
import historyActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_HISTORY_REQUEST },
  Creators: { loadHistorySuccess, loadHistoryFailure },
} = historyActions;

const {
  Creators: { setError },
} = errorActions;

function* loadHistory({ payload }) {
  const { start, end, used, limit } = payload;
  try {
    const { data } = yield call(
      api.get,
      `/history?startDate=${start}&endDate=${end}&limit=${used || limit}`
    );
    yield put(loadHistorySuccess(data));
  } catch (error) {
    yield put(loadHistoryFailure(error));
    yield put(setError('Load history error.'));
  }
}

export function* historySaga() {
  yield takeLatest(LOAD_HISTORY_REQUEST, loadHistory);
}
