/* eslint-disable react/no-danger */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { Checkbox } from '@mui/material';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';
const TermsConditions = ({
  userExist,
  selectedClub,
  selectedPlan,
  card,
  emailAddress,
  userPersonalInfo,
  setTermsAccept,
  termsAccept,
  plan,
  onNext,
  isMobile,
  onBackTerms,
  getPaymentDetails,
  referralPerson,
}) => {
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const scrollContainerRef = useRef(null);
  const checkboxRef = useRef(null);
  const parts = (userPersonalInfo?.birthday).split('/');
  const [month, day, year] = parts;

  var userDetailsForCreation = {
    onlyPreview: true,
    gender: userPersonalInfo?.gender,
    firstName: userPersonalInfo?.firstName,
    lastName: userPersonalInfo?.lastName,
    dateOfBirth: `${year}-${month}-${day}`,
    email: emailAddress,
    phoneNumber: parseInt(userPersonalInfo?.phone.replace(/[^0-9]/g, '')),
    contactAddressLine1: userPersonalInfo?.address,
    contactCity: userPersonalInfo?.city,
    contactState: userPersonalInfo?.state,
    contactZip: userPersonalInfo?.zipCode,
    emergencyContactName: userPersonalInfo?.emergencyContactName,
    emergencyContactPhone: parseInt(
      userPersonalInfo?.emergencyContactPhone.replace(/[^0-9]/g, '')
    ),
    homeClubId: parseInt(selectedClub?.clubautomationclubidC),
    membershipId: selectedPlan?.id,
    // memberPortalPassword: 'A9cebc648-2e9e-4c6d-8c7a-6ea5ff36d',
    paymentMethod: 'credit card',
    eftAuthAccept: true,
    signature: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    billingAddressLine1: userPersonalInfo?.address,
    billingCity: userPersonalInfo?.city,
    billingState: userPersonalInfo?.state,
    billingZip: userPersonalInfo?.zipCode,
    termsAccept: termsAccept,
    waiverReleaseAccept: true,
    billingName: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    i4goUniqueId: card?.i4goUniqueId,
    maskedCardNumber: card?.cardNumber,
    cardExpirationMonth: parseInt(card?.cardExpirationMonth),
    cardExpirationYear: parseInt(card?.cardExpirationYear),
    referredByCaId: Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : '',
    referredByName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : '',
  };

  var userDetailsForUpdation = {
    onlyPreview: true,
    gender: userPersonalInfo?.gender,
    firstName: userPersonalInfo?.firstName,
    lastName: userPersonalInfo?.lastName,
    dateOfBirth: `${year}-${month}-${day}`,
    email: 'x5Tt34FB4Fzw@gmail.com',
    phoneNumber: parseInt(userPersonalInfo?.phone.replace(/[^0-9]/g, '')),
    contactAddressLine1: userPersonalInfo?.address,
    contactCity: userPersonalInfo?.city,
    contactState: userPersonalInfo?.state === '' ? 'CA' : userPersonalInfo?.state,
    contactZip: userPersonalInfo?.zipCode,
    emergencyContactName: userPersonalInfo?.emergencyContactName,
    emergencyContactPhone: parseInt(
      userPersonalInfo?.emergencyContactPhone.replace(/[^0-9]/g, '')
    ),
    homeClubId: parseInt(selectedClub?.clubautomationclubidC),
    membershipId: selectedPlan?.id,
    // memberPortalPassword: 'A9cebc648-2e9e-4c6d-8c7a-6ea5ff36d',
    paymentMethod: 'credit card',
    eftAuthAccept: true,
    signature: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    billingAddressLine1: userPersonalInfo?.address,
    billingCity: userPersonalInfo?.city,
    billingState: userPersonalInfo?.state === '' ? 'CA' : userPersonalInfo?.state,
    billingZip: userPersonalInfo?.zipCode,
    termsAccept: termsAccept,
    waiverReleaseAccept: true,
    billingName: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    i4goUniqueId: card?.i4goUniqueId,
    maskedCardNumber: card?.cardNumber,
    cardExpirationMonth: parseInt(card?.cardExpirationMonth),
    cardExpirationYear: parseInt(card?.cardExpirationYear),
    referredByCaId: Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : '',
    referredByName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : '',
  };

  // testOLSemailForTC@gmail.com
  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    const scrolledToBottom =
      scrollContainer.scrollHeight - scrollContainer.scrollTop - 100 <
      scrollContainer.clientHeight;
    setTermsAccept(scrolledToBottom);
  };

  const handleCheckboxChange = () => {
    setIsNextButtonEnabled(checkboxRef.current.checked);
  };

  const handleNext = async () => {
    if (checkboxRef.current.checked && !userExist) {
      let data = {
        user: userDetailsForCreation,
        isPhotoUpload: false,
        photo: null,
      };
      await getPaymentDetails(data);
      onNext();
    } else if (checkboxRef.current.checked && userExist) {
      let data = {
        user: userDetailsForUpdation,
        isPhotoUpload: false,
        photo: null,
      };
      await getPaymentDetails(data);
      onNext();
    }
  };

  const handleBack = () => {
    onBackTerms();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={`${styles.container} ${isMobile ? styles.mobile : ''}`}>
        <div
          className={`${styles.scroll} ${isMobile ? styles.mobile : ''}`}
          ref={scrollContainerRef}
          onScroll={handleScroll}
          style={{
            maxHeight: '350px',
            overflowY: 'scroll',
            padding: '10px',
          }}
        >
          <p
            className={`${styles.card} ${isMobile ? styles.mobile : ''}`}
            dangerouslySetInnerHTML={{
              __html: plan?.contractDetails.replace(
                /<p>/g,
                '<p style="font-family: Montserrat-light;">'
              ),
            }}
          />
        </div>
        <div className={`${styles.checkbox} ${isMobile ? styles.mobile : ''}`}>
          <Checkbox
            inputRef={checkboxRef}
            onClick={() => handleCheckboxChange()}
            disabled={!termsAccept}
            size={isMobile ? 'large' : 'large'}
            sx={{
              '& .MuiSvgIcon-root': {
                color: termsAccept === true ? 'var(--orange-color)' : 'none',
              },
              '&.Mui-checked': {
                color: 'var(--orange-color)',
              },
            }}
          />
          <label
            className={`${styles.label} ${isMobile ? styles.mobile : ''} `}
            htmlFor="myCheckbox"
          >
            I agree to the FITNESS SF{' '}
            <a
              href="https://www.fitnesssf.com/liability-waiver"
              className={styles.link}
              target="_blank"
            >Liability Waiver</a>,{' '}
            <a
              href="https://www.fitnesssf.com/terms-and-conditions"
              className={styles.link}
              target="_blank"
            >Terms & Conditions</a>,{' '}
            <a
              href="https://www.fitnesssf.com/privacy-policy"
              className={styles.link}
              target="_blank"
            >Privacy Policy</a>,{' '}
            and I am at least 18 years of age.
          </label>
        </div>

        <div className={styles.mainScroll} />
        <BackAndNextButton
          backButtonName="Back"
          nextButtonName="Next"
          onBack={() => handleBack()}
          onNext={() => handleNext()}
          nextButtonDisable={!isNextButtonEnabled}
        />
      </div>
    </>
  );
};

TermsConditions.propTypes = {
  plan: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default TermsConditions;
