import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  history: null,
  error: null,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadHistorySuccess = (state = INITIAL_STATE, { history }) => {
  return {
    ...state,
    isRequesting: false,
    history,
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    history: null,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_HISTORY_REQUEST]: request,
  [Types.LOAD_HISTORY_SUCCESS]: loadHistorySuccess,
  [Types.LOAD_HISTORY_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
