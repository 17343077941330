/* eslint-disable no-use-before-define */
import {
  takeLeading,
  call,
  put,
  select,
  take,
  takeEvery,
  fork,
} from 'redux-saga/effects';
import api from 'services/api';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import { getSocket } from 'utils/socket';
import messagesActions from './actions';
import errorActions from '../error/actions';

const {
  Types: {
    LOAD_MESSAGES_REQUEST,
    SEND_MESSAGE_REQUEST,
    RECEIVE_MESSAGE,
    RECEIVE_READ_MESSAGES,
    SELECT_TARGET_DIALOG,
    SET_TRAINER_ID,
  },
  Creators: {
    selectTargetDialog,
    loadMessagesSuccess,
    loadMessagesFailure,
    sendMessageSuccess,
    sendMessageFailure,
    receiveMessageSuccess,
    receiveMessageFailure,
    filterMessagesHistory,
    receiveMessageSuccessWithUpdate,
    receiveReadMessagesFailure,
    receiveReadMessagesSuccess,
    receiveReadMessagesSuccessWithUpdate,
    updateMessagesHistory,
  },
} = messagesActions;

const {
  Creators: { setError },
} = errorActions;

const getMessageText = state => state.messages.messageText;
const getTargetDialog = state => state.messages.targetDialog;
const getMessagesHistory = state => state.messages.messagesHistory;
const getResentMessages = state => state.messages.resentMessages;
const getMyId = state => state.auth.user.sfId;
const getTargetDialogMessagesHistory = state =>
  state.messages.targetDialogMessagesHistory;

function* loadMessages() {
  try {
    const { data } = yield call(api.get, `/messages`);
    yield put(loadMessagesSuccess(data.messagesHistory, data.resentMessages));
  } catch (error) {
    yield put(loadMessagesFailure(error));
    // yield put(setError('Failed to load messages.'));
  }
}
function* handleRecentMessages(message) {
  const recentMessages = yield select(getResentMessages);
  const clonedRecentMessages = cloneDeep(recentMessages);
  const myId = yield select(getMyId);
  let photo = null;
  const personId =
    myId === message.senderUserId ? message.receiverUserId : message.senderUserId;
  const filteredRecentMessages = clonedRecentMessages.filter(recentMessage => {
    if (recentMessage.senderUserId !== personId) {
      if (recentMessage.receiverUserId === personId) {
        photo = recentMessage.photo;
        return false;
      }
    } else if (recentMessage.senderUserId === personId) {
      photo = recentMessage.photo;
      return false;
    }
    return true;
  });

  filteredRecentMessages.unshift({ ...message, photo });
  return filteredRecentMessages;
}
function* sendMessage() {
  try {
    const text = yield select(getMessageText);
    const myId = yield select(getMyId);
    const { isMyMessage, receiverUserId, senderUserId, isDirector } = yield select(
      getTargetDialog
    );
    const receiver = isMyMessage ? receiverUserId : senderUserId;
    const body = { receiverUserId: receiver, text, isDirector, senderUserId: myId };
    const socket = getSocket();
    socket.emit('send message', body);
    yield put(sendMessageSuccess());
  } catch (error) {
    yield put(sendMessageFailure(error));
    yield put(setError('Failed to send a message.'));
  }
}

function* receiveMessage({ data }) {
  try {
    const myId = yield select(getMyId);
    const targetDialog = yield select(getTargetDialog);

    if (data.senderUserId === myId) {
      const targetDialogPersonId =
        targetDialog && myId === targetDialog.senderUserId
          ? targetDialog.receiverUserId
          : targetDialog.senderUserId;
      const receivedMessagePersonId =
        myId === data.senderUserId ? data.receiverUserId : data.senderUserId;
      if (targetDialogPersonId === receivedMessagePersonId) {
        const messagesHistory = yield select(getMessagesHistory);
        const clonedMessagesHistory = cloneDeep(messagesHistory);
        clonedMessagesHistory.push({ ...data, photo: targetDialog.photo });
        const updatedTargetDialog = { ...data, photo: targetDialog.photo };
        const updatedResentMessages = yield handleRecentMessages({
          ...data,
          photo: targetDialog.photo,
        });
        const targetDialogMessagesHistory = yield select(getTargetDialogMessagesHistory);
        const clonedTargetDialogMessagesHistory = cloneDeep(targetDialogMessagesHistory);
        clonedTargetDialogMessagesHistory.push({
          ...data,
          photo: targetDialog.photo,
        });
        yield put(
          receiveMessageSuccessWithUpdate(
            clonedMessagesHistory,
            updatedTargetDialog,
            updatedResentMessages,
            clonedTargetDialogMessagesHistory
          )
        );
      } else {
        const messagesHistory = yield select(getMessagesHistory);
        const clonedMessagesHistory = cloneDeep(messagesHistory);
        clonedMessagesHistory.push(data);
        const updatedResentMessages = yield handleRecentMessages(data);
        yield put(receiveMessageSuccess(clonedMessagesHistory, updatedResentMessages));
      }
    } else {
      const targetDialogPersonId =
        targetDialog && myId === targetDialog.senderUserId
          ? targetDialog.receiverUserId
          : targetDialog.senderUserId;
      const receivedMessagePersonId =
        myId === data.senderUserId ? data.receiverUserId : data.senderUserId;

      if (targetDialogPersonId === receivedMessagePersonId) {
        const messagesHistory = yield select(getMessagesHistory);
        const clonedMessagesHistory = cloneDeep(messagesHistory);
        clonedMessagesHistory.push({ ...data, photo: targetDialog.photo, isRead: true });
        const updatedTargetDialog = { ...data, photo: targetDialog.photo, isRead: true };
        const updatedResentMessages = yield handleRecentMessages({
          ...data,
          photo: targetDialog.photo,
          isRead: true,
        });
        const targetDialogMessagesHistory = yield select(getTargetDialogMessagesHistory);
        const clonedTargetDialogMessagesHistory = cloneDeep(targetDialogMessagesHistory);
        clonedTargetDialogMessagesHistory.push({
          ...data,
          photo: targetDialog.photo,
          isRead: true,
        });
        yield put(
          receiveMessageSuccessWithUpdate(
            clonedMessagesHistory,
            updatedTargetDialog,
            updatedResentMessages,
            clonedTargetDialogMessagesHistory
          )
        );
        const socket = getSocket();
        socket.emit('message read', {
          ids: [data.id],
          personId: receivedMessagePersonId,
          myId,
        });
      } else {
        const messagesHistory = yield select(getMessagesHistory);
        const clonedMessagesHistory = cloneDeep(messagesHistory);
        clonedMessagesHistory.push(data);
        const updatedResentMessages = yield handleRecentMessages(data);
        yield put(receiveMessageSuccess(clonedMessagesHistory, updatedResentMessages));
      }
    }
  } catch (err) {
    yield put(receiveMessageFailure(err));
    yield put(setError('Receive message failed.'));
  }
}

function* filterMessagesHistorySaga({ targetDialog }) {
  try {
    const myId = yield select(getMyId);
    const { receiverUserId, senderUserId } = targetDialog;
    const targetDialogPersonId = myId === senderUserId ? receiverUserId : senderUserId;
    const messagesHistory = yield select(getMessagesHistory);
    const recentMessages = yield select(getResentMessages);
    const clonedRecentMessages = cloneDeep(recentMessages);

    const filteredMessages = messagesHistory.filter(message => {
      if (message.senderUserId !== targetDialogPersonId) {
        if (message.receiverUserId === targetDialogPersonId) {
          return true;
        }
      } else if (message.senderUserId === targetDialogPersonId) {
        return true;
      }
      return false;
    });

    const updatedMessagesHistory = messagesHistory.map(message => {
      if (message.senderUserId !== targetDialogPersonId) {
        if (message.receiverUserId === targetDialogPersonId) {
          return { ...message, isRead: true };
        }
      } else if (message.senderUserId === targetDialogPersonId) {
        return { ...message, isRead: true };
      }
      return message;
    });

    const updatedRecentMessages = clonedRecentMessages.map(message => {
      if (
        message.receiverUserId === receiverUserId &&
        message.senderUserId === senderUserId
      )
        return { ...message, isRead: true };
      return message;
    });
    yield watchMarkMessagesAsRead(filteredMessages);
    yield put(updateMessagesHistory(updatedMessagesHistory, updatedRecentMessages));
    const sorted = orderBy(filteredMessages, ['createdAt'], ['asc']);
    yield put(filterMessagesHistory(sorted));
  } catch (err) {
    yield put(setError('Load dialog error.'));
  }
}

function* findDialog({ trainerId }) {
  yield take(['LOAD_MESSAGES_SUCCESS']);
  const resentMessages = yield select(getResentMessages);
  if (trainerId) {
    const dialog = resentMessages.find(
      resent => resent.receiverUserId === trainerId || resent.senderUserId === trainerId
    );
    if (dialog) {
      yield put(selectTargetDialog(dialog));
    }
  } else {
    const dialog = resentMessages.find(resent => resent.isDirector);
    if (dialog) {
      yield put(selectTargetDialog(dialog));
    }
  }
}

function* watchMarkMessagesAsRead(messages) {
  if (!messages.length) return;
  const filteredMessages = messages.filter(
    message => !message.isRead && !message.isMyMessage
  );
  const messagesIds = filteredMessages.map(message => message.id);

  if (messagesIds.length) {
    const socket = getSocket();
    const myId = yield select(getMyId);
    const personId =
      myId === filteredMessages[0].senderUserId
        ? filteredMessages[0].receiverUserId
        : filteredMessages[0].senderUserId;
    socket.emit('message read', { ids: messagesIds, personId, myId });
  }
}

function* receiveReadMessages({ data }) {
  try {
    const targetDialog = yield select(getTargetDialog);
    if (targetDialog) {
      const messagesHistory = yield select(getMessagesHistory);
      const targetDialogMessagesHistory = yield select(getTargetDialogMessagesHistory);
      const recentMessages = yield select(getResentMessages);

      const clonedMessagesHistory = cloneDeep(messagesHistory);
      const clonedTargetDialogMessagesHistory = cloneDeep(targetDialogMessagesHistory);
      const clonedRecentMessages = cloneDeep(recentMessages);

      const markedMessagesHistory = clonedMessagesHistory.map(message => {
        if (data.includes(message.id)) return { ...message, isRead: true };
        return message;
      });
      const markedTargetDialogMessagesHistory = clonedTargetDialogMessagesHistory.map(
        message => {
          if (data.includes(message.id)) return { ...message, isRead: true };
          return message;
        }
      );
      const markedRecentMessages = clonedRecentMessages.map(message => {
        if (data.includes(message.id)) return { ...message, isRead: true };
        return message;
      });
      yield put(
        receiveReadMessagesSuccessWithUpdate(
          markedMessagesHistory,
          markedTargetDialogMessagesHistory,
          markedRecentMessages
        )
      );
    } else {
      const messagesHistory = yield select(getMessagesHistory);
      const recentMessages = yield select(getResentMessages);

      const clonedMessagesHistory = cloneDeep(messagesHistory);
      const clonedRecentMessages = cloneDeep(recentMessages);

      const markedMessagesHistory = clonedMessagesHistory.map(message => {
        if (data.includes(message.id)) return { ...message, isRead: true };
        return message;
      });
      const markedRecentMessages = clonedRecentMessages.map(message => {
        if (data.includes(message.id)) return { ...message, isRead: true };
        return message;
      });

      yield put(receiveReadMessagesSuccess(markedMessagesHistory, markedRecentMessages));
    }
  } catch (err) {
    yield put(receiveReadMessagesFailure(err));
  }
}
export function* messagesSaga() {
  yield takeLeading(LOAD_MESSAGES_REQUEST, function* flow() {
    yield fork(loadMessages);
  });
  yield takeLeading(SEND_MESSAGE_REQUEST, sendMessage);
  yield takeLeading(RECEIVE_MESSAGE, receiveMessage);
  yield takeLeading(RECEIVE_READ_MESSAGES, receiveReadMessages);
  yield takeEvery(SELECT_TARGET_DIALOG, filterMessagesHistorySaga);
  yield takeEvery(SET_TRAINER_ID, findDialog);
}
