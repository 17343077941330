import store from 'store';
import history from 'store/history';
import { localBaseUrl } from '../axiosBaseUrls/index.js';

localBaseUrl.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;

    if (status === 401 || status === 403) {
      if (
        history.location.pathname !== '/login' &&
        history.location.pathname !== '/new_password' &&
        history.location.pathname !== '/reset_password'
      ) {
        store.dispatch({ type: 'LOGOUT_REQUEST' });
      }
    }

    return Promise.reject(error);
  }
);

const setHeaders = headers => {
  const defaultHeader = { 'Content-Type': 'application/json' };
  if (headers) return Object.assign(defaultHeader, headers);
  return defaultHeader;
};

const setAuthTokenToHeader = token => {
  if (token) {
    localBaseUrl.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete localBaseUrl.defaults.headers.common.Authorization;
  }
};

const get = (url, headers) => {
  return localBaseUrl.get(`${url}`, setHeaders(headers));
};

const post = (url, data, headers) => {
  const currentHeaders = setHeaders(headers);
  return localBaseUrl.post(`${url}`, data, currentHeaders);
};

const patch = (url, data, headers) => {
  return localBaseUrl.patch(`${url}`, data, setHeaders(headers));
};

const put = (url, data, headers) => {
  return localBaseUrl.put(`${url}`, data, setHeaders(headers));
};

const remove = (url, data, headers) => {
  return localBaseUrl.delete(`${url}`, setHeaders(headers));
};

export default {
  get,
  post,
  put,
  patch,
  remove,
  setAuthTokenToHeader,
};
