import React from 'react';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import PropTypes from 'prop-types';
import { arrowLeft } from 'assets/icons';
import MobileContext from 'context/MobileContext';
import { useContext } from 'react';

const BackAndNextButton = ({
  onNext,
  onBack,
  backButtonDisable,
  nextButtonDisable,
  backButtonName,
  nextButtonName,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <>
      <div className={`${isMobile ? styles.mobContainer : styles.container}`}>
        <div className={`${isMobile ? styles.mobInnerContainer : styles.innerContainer}`}>
          <Button
            disabled={backButtonDisable}
            className={`${isMobile ? styles.mobBackButton : styles.backButton}`}
            onClick={onBack}
            width={isMobile ? '110px' : ''}
          >
            {!isMobile ? (
              <>
                <img className={styles.arrow} src={arrowLeft} alt='back button'/>
                {backButtonName}
              </>
            ) : (
              <>{backButtonName}</>
            )}
          </Button>
          <Button
            disabled={nextButtonDisable}
            className={`${isMobile ? styles.mobNextButton : styles.nextButton}`}
            onClick={onNext}
            width={
              isMobile ? (nextButtonName === 'Submit Payment' ? '230px' : '160px') : ''
            }
          >
            {nextButtonName}
          </Button>
        </div>
      </div>
    </>
  );
};

BackAndNextButton.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  backButtonDisable: PropTypes.bool,
  nextButtonDisable: PropTypes.bool,
  nextButtonName: PropTypes.string,
  backButtonName: PropTypes.string,
};
export default BackAndNextButton;
