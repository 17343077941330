import React from 'react';
import { roundLogo } from 'assets/logos';
import { arrowLeftBlack } from 'assets/icons';
import history from 'store/history';
import styles from './styles.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      <img src={roundLogo} alt="" />
      <p>Page not found</p>
      <div className={styles.button} onClick={() => history.push('/')}>
        <img src={arrowLeftBlack} alt="" />
        <p> Go back</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
