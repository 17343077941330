import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  chatbotSessionIdRequest: ['sessionId'],
  chatbotSessionIdSuccess: ['sessionIdInfo'],
  chatbotSessionIdFailure: ['error'],

  updateChatbotRecordRequest: ['records'],
  updateChatbotRecordSuccess: ['chatbotRecords'],
  updateChatbotRecordFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
