export default {
  padding: '8px 0 8px 12px',
  cursor: 'pointer',
  width: '100%',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontFamily: 'Futura Book',
  fontSize: '16px',
  lineHeight: '21px',
  boxSizing: 'border-box',
};
