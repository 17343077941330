import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isMessageSending: false,
  messageText: '',
  targetDialog: '',
  targetDialogMessagesHistory: [],
  messagesHistory: [],
  resentMessages: [],
  searchValue: '',
  error: null,
};

const clearMessagesState = () => {
  return INITIAL_STATE;
};

const updateMessages = (
  state = INITIAL_STATE,
  { messagesHistory, targetDialog, resentMessages, targetDialogMessagesHistory }
) => {
  return {
    ...state,
    messagesHistory,
    targetDialog,
    resentMessages,
    targetDialogMessagesHistory,
  };
};

const filterMessagesHistory = (
  state = INITIAL_STATE,
  { targetDialogMessagesHistory }
) => {
  return {
    ...state,
    targetDialogMessagesHistory,
  };
};
const selectTargetDialog = (state = INITIAL_STATE, { targetDialog }) => {
  return {
    ...state,
    targetDialog,
  };
};
const clearTargetDialog = (state = INITIAL_STATE, { targetDialog }) => {
  return {
    ...state,
    targetDialog: '',
    targetDialogMessagesHistory: [],
  };
};
const typeMessage = (state = INITIAL_STATE, { messageText }) => {
  return {
    ...state,
    messageText,
  };
};
const loadMessagesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadMessagesSuccess = (
  state = INITIAL_STATE,
  { messagesHistory, resentMessages }
) => {
  return {
    ...state,
    isRequesting: false,
    messagesHistory,
    resentMessages,
  };
};
const loadMessagesFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    messages: null,
    error,
  };
};

const sendMessageRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isMessageSending: true,
    error: null,
  };
};
const sendMessageSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isMessageSending: false,
    messageText: '',
  };
};
const sendMessageFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isMessageSending: false,
    error,
  };
};

const receiveMessageSuccess = (
  state = INITIAL_STATE,
  { messagesHistory, resentMessages }
) => {
  return {
    ...state,
    messagesHistory,
    resentMessages,
  };
};

const receiveMessageSuccessWithUpdate = (
  state = INITIAL_STATE,
  { messagesHistory, targetDialog, resentMessages, targetDialogMessagesHistory }
) => {
  return {
    ...state,
    messagesHistory,
    resentMessages,
    targetDialogMessagesHistory,
    targetDialog,
  };
};
const receiveMessageFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
  };
};
const receiveReadMessagesFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
  };
};
const receiveReadMessagesSuccess = (
  state = INITIAL_STATE,
  { messagesHistory, resentMessages }
) => {
  return {
    ...state,
    messagesHistory,
    resentMessages,
  };
};

const receiveReadMessagesSuccessWithUpdate = (
  state = INITIAL_STATE,
  { messagesHistory, targetDialogMessagesHistory, resentMessages }
) => {
  return {
    ...state,
    messagesHistory,
    targetDialogMessagesHistory,
    resentMessages,
  };
};
const updateMessagesHistory = (
  state = INITIAL_STATE,
  { messagesHistory, resentMessages }
) => {
  return {
    ...state,
    messagesHistory,
    resentMessages,
  };
};
export const HANDLERS = {
  [Types.CLEAR_MESSAGES_STATE]: clearMessagesState,
  [Types.UPDATE_MESSAGES]: updateMessages,
  [Types.UPDATE_MESSAGES_HISTORY]: updateMessagesHistory,
  [Types.FILTER_MESSAGES_HISTORY]: filterMessagesHistory,
  [Types.TYPE_MESSAGE]: typeMessage,
  [Types.SELECT_TARGET_DIALOG]: selectTargetDialog,
  [Types.CLEAR_TARGET_DIALOG]: clearTargetDialog,

  [Types.LOAD_MESSAGES_REQUEST]: loadMessagesRequest,
  [Types.LOAD_MESSAGES_SUCCESS]: loadMessagesSuccess,
  [Types.LOAD_MESSAGES_FAILURE]: loadMessagesFailure,

  [Types.SEND_MESSAGE_REQUEST]: sendMessageRequest,
  [Types.SEND_MESSAGE_SUCCESS]: sendMessageSuccess,
  [Types.SEND_MESSAGE_FAILURE]: sendMessageFailure,

  [Types.RECEIVE_MESSAGE_SUCCESS_WITH_UPDATE]: receiveMessageSuccessWithUpdate,
  [Types.RECEIVE_MESSAGE_SUCCESS]: receiveMessageSuccess,
  [Types.RECEIVE_MESSAGE_FAILURE]: receiveMessageFailure,
  [Types.RECEIVE_READ_MESSAGES_FAILURE]: receiveReadMessagesFailure,

  [Types.RECEIVE_READ_MESSAGES_SUCCESS_WITH_UPDATE]: receiveReadMessagesSuccessWithUpdate,
  [Types.RECEIVE_READ_MESSAGES_SUCCESS]: receiveReadMessagesSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
