import { takeEvery, put, spawn, call, select } from 'redux-saga/effects';
import api from 'services/api';
import actions from './actions';

const {
  Types: { CHECK_CALL_PERMISSION_REQUEST },
  Creators: { checkCallPermissionSuccess, checkCallPermissionFailure },
} = actions;

const getMyId = state => state.auth.user.sfId;
const getCallId = state => state.sessionCall.callId;

function* watchCheckSessionCallPermission() {
  yield takeEvery(CHECK_CALL_PERMISSION_REQUEST, function* check() {
    try {
      const callId = yield select(getCallId);
      const { data } = yield call(api.get, `/calls?callId=${callId}`);
      const availableIds = data.availableIds.split(',');

      const sfId = yield select(getMyId);
      const friendId = sfId === availableIds[0] ? availableIds[1] : availableIds[0];
      yield put(checkCallPermissionSuccess(friendId, data.username, data.credential));
    } catch (error) {
      yield put(checkCallPermissionFailure(error));
    }
  });
}

export function* sessionCallSaga() {
  yield spawn(watchCheckSessionCallPermission);
}
