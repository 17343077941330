import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Hide } from 'assets/icons/hide.svg';
import { ReactComponent as Show } from 'assets/icons/show.svg';
import { lockIcon } from 'assets/icons';
import PasswordStrength from './PasswordStrength';
import styles from './styles.module.css';

const PasswordInput = ({
  styleType,
  name,
  value,
  invalid,
  className,
  width,
  placeholder,
  hasvalue,
  withStrength,
  icon,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const classNames = `${styles.input} ${styles.inputPassword} ${className} ${invalid &&
    styles.invalid} ${icon && styles.inputPadding}`;
  return (
    <>
      <div className={styles.wrapper}>
        {icon && <img src={lockIcon} className={styles.inputIcon} alt="" />}
        <div className={styles.icon} onClick={() => setShow(!show)}>
          {show ? (
            <Show
              width="18"
              style={{
                filter:
                  'invert(38%) sepia(65%) saturate(3354%) hue-rotate(360deg) brightness(99%) contrast(108%)',
                pointerEvents: 'none',
              }}
            />
          ) : (
            <Hide
              width="18"
              style={{
                filter: 'grayscale(100%) brightness(0)',
                pointerEvents: 'none',
              }}
            />
          )}
        </div>

        <input
          className={classNames}
          name={name}
          id={name}
          value={value}
          style={{ width }}
          hasvalue={hasvalue}
          {...props}
          type={show ? 'text' : 'password'}
        />

        <label
          className={`${styles.formLabel} ${icon && styles.inputWithIcon}`}
          htmlFor={name}
        >
          {placeholder}
        </label>
      </div>
      {withStrength && <PasswordStrength width={width} value={value} />}
    </>
  );
};

PasswordInput.propTypes = {
  type: PropTypes.string,
  styleType: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalid: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  hasvalue: PropTypes.string.isRequired,
  withStrength: PropTypes.bool,
  icon: PropTypes.string,
};

PasswordInput.defaultProps = {
  type: 'text',
  styleType: '',
  name: '',
  value: '',
  invalid: false,
  className: '',
  width: '360px',
  placeholder: '',
  withStrength: false,
  icon: '',
};

export default PasswordInput;
