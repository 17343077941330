/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import inputStyles from 'components/shared/Input/newOls.module.css';
import styles from './style.module.css';
import MobileContext from 'context/MobileContext';
import SmallMobileContext from 'context/SmallMobileContext';
import { useContext } from 'react';

const Textfield = ({
  value,
  name,
  id='',
  label,
  invalid,
  className,
  width,
  error,
  inputClassName,
  rightIcon,
  onChange,
  defaultValue,
  boxShadow,
  ...props
}) => {
  const labelRef = useRef(null);
  const isMobile = useContext(MobileContext);
  const isSmallMobile = useContext(SmallMobileContext);

  const [isHideLabel, setHideLabel] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (labelRef.current && !labelRef.current.contains(event.target))
        setHideLabel(false);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [labelRef, isHideLabel]);

  return (
    <div className={`${styles.field} ${className}`}>
      <InputMask
        onChange={onChange}
        type="text"
        id={id}
        name={name}
        className={`${inputStyles.input} ${invalid && inputStyles.invalid} ${
          styles.inputMask
        } ${invalid && styles.invalid} ${inputClassName}`}
        invalid={invalid}
        error={error}
        value={value}
        hasvalue={value?.length > 0 ? 'true' : 'false'}
        style={{ width, boxShadow: boxShadow }}
        {...props}
        defaultValue={defaultValue}
      />
      <label
        className={`${inputStyles.formLabel} ${styles.label} ${isHideLabel &&
          styles.hideLabel}`}
        onClick={() => setHideLabel(true)}
        ref={labelRef}
        htmlFor={name}
      >
        {label}
      </label>
      {rightIcon && (
        <img src={rightIcon} className={inputStyles.rightIconTextfield} alt="" />
      )}

      <p
        className={`${styles.error} ${isMobile ? styles.mob : null} ${
          isSmallMobile ? styles.smallMob : null
        }`}
        style={{ width }}
      >
        {invalid && error && error}
      </p>
    </div>
  );
};

Textfield.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  width: PropTypes.string,
  rightIcon: PropTypes.string,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  boxShadow: PropTypes.string,
};

Textfield.defaultProps = {
  value: '',
  name: '',
  label: '',
  rightIcon: '',
  onChange: null,
  className: '',
  inputClassName: '',
  width: '',
  invalid: false,
  error: '',
  defaultValue: '',
  boxShadow: '',
};

export default Textfield;
