import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

const Checkbox = ({ text, value, activeDisable, orangeBorder, ...props }) => {
  return (
    <label
      className={`${styles.container} ${!orangeBorder &&
        styles.blackContainer} ${activeDisable && styles.activeDisable}`}
    >
      <input type="checkbox" checked={value} {...props} className={styles.input} />
      <span className={`${styles.checkmark} ${orangeBorder && styles.orangeBorder}`} />
    </label>
  );
};

Checkbox.propTypes = {
  text: PropTypes.string,
  value: PropTypes.bool,
  activeDisable: PropTypes.bool,
  orangeBorder: PropTypes.bool,
};

Checkbox.defaultProps = {
  text: '',
  value: false,
  activeDisable: false,
  orangeBorder: false,
};

export default Checkbox;
