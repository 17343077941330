import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loginRequest: ['payload'],
  loginSuccess: ['user'],
  loginFailure: ['error'],

  logoutRequest: [],
  logoutSuccess: [],
  logoutFailure: ['error'],

  requestResetRequest: ['email'],
  requestResetSuccess: [],
  requestResetFailure: ['error'],

  resetPasswordRequest: ['payload'],
  resetPasswordSuccess: ['user'],
  resetPasswordFailure: ['error'],

  updatePasswordRequest: ['payload'],
  updatePasswordSuccess: [],
  updatePasswordFailure: ['error'],

  loadProfileRequest: [''],
  loadProfileSuccess: ['profile'],
  loadProfileFailure: ['error'],

  changeFavoriteLocationRequest: ['payload'],
  changeFavoriteLocationSuccess: ['user'],
  changeFavoriteLocationFailure: ['error'],

  changeProfileInfoRequest: ['payload'],
  changeProfileInfoSuccess: ['profile'],
  changeProfileInfoFailure: ['error'],

  loadMembershipRequest: [''],
  loadMembershipSuccess: ['membership'],
  loadMembershipFailure: ['error'],

  postCancelationDateRequest: ['payload'],
  postCancelationDateSuccess: ['status'],
  postCancelationDateFailure: ['error'],

  clearAuthError: [],

  checkFamilyRequest: ['email'],
  checkFamilySuccess: ['isFamily'],
  checkFamilyFailure: ['error'],

  checkMemberStatusRequest: [],
  checkMemberStatusSuccess: ['memberStatus'],
  checkMemberStatusFailure: ['error'],

  setVaccineStatusRequest: ['payload'],
  setVaccineStatusSuccess: [],
  setVaccineStatusFailure: ['error'],

  loginWithService: ['data'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
