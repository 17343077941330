import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  billing: null,
  newOlsbilling: null,
  error: null,
  url: null,
  cardInfo: null,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};

const loadBillingSuccess = (state = INITIAL_STATE, { billing }) => {
  return {
    ...state,
    isRequesting: false,
    billing,
  };
};

const loadNewOlsBillingSuccess = (state = INITIAL_STATE, { newOlsbilling }) => {
  return {
    ...state,
    isRequesting: false,
    newOlsbilling,
  };
};


const makePrimarySuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};
const makePaymentSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
  };
};

const addNewCardSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};

const saveCardInfoSuccess = (state = INITIAL_STATE, { cardInfo }) => {
  return {
    ...state,
    isRequesting: false,
    cardInfo,
  };
};

const deleteCardSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};

const loadBillingUrlSuccess = (state = INITIAL_STATE, { url }) => {
  return {
    ...state,
    isRequesting: false,
    url,
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    billing: null,
    error,
  };
};

export const HANDLERS = {
         [Types.LOAD_BILLING_REQUEST]: request,
         [Types.LOAD_BILLING_SUCCESS]: loadBillingSuccess,
         [Types.LOAD_BILLING_FAILURE]: failure,

         [Types.LOAD_NEW_OLS_BILLING_REQUEST]: request,
         [Types.LOAD_NEW_OLS_BILLING_SUCCESS]: loadNewOlsBillingSuccess,
         [Types.LOAD_NEW_OLS_BILLING_FAILURE]: failure,

         [Types.MAKE_PRIMARY_REQUEST]: request,
         [Types.MAKE_PRIMARY_SUCCESS]: makePrimarySuccess,
         [Types.MAKE_PRIMARY_FAILURE]: failure,

         [Types.MAKE_PAYMENT_REQUEST]: request,
         [Types.MAKE_PAYMENT_SUCCESS]: makePaymentSuccess,
         [Types.MAKE_PAYMENT_FAILURE]: failure,

         [Types.ADD_NEW_CARD_REQUEST]: request,
         [Types.ADD_NEW_CARD_SUCCESS]: addNewCardSuccess,
         [Types.ADD_NEW_CARD_FAILURE]: failure,

         [Types.SAVE_CARD_INFO_REQUEST]: request,
         [Types.SAVE_CARD_INFO_SUCCESS]: saveCardInfoSuccess,
         [Types.SAVE_CARD_INFO_FAILURE]: failure,

         [Types.DELETE_CARD_REQUEST]: request,
         [Types.DELETE_CARD_SUCCESS]: deleteCardSuccess,
         [Types.DELETE_CARD_FAILURE]: failure,

         [Types.LOAD_BILLING_URL_REQUEST]: request,
         [Types.LOAD_BILLING_URL_SUCCESS]: loadBillingUrlSuccess,
         [Types.LOAD_BILLING_URL_FAILURE]: failure,
       };

export default createReducer(INITIAL_STATE, HANDLERS);
