import { getCookie } from 'utils/cookies';

function getNewOlSToken() {
  if (navigator.cookieEnabled) return getCookie('new_ols_access_token');
  return null;
}

function getToken() {
  if (navigator.cookieEnabled) return getCookie('access_token');
  return null;
}

function decodeToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function setToken(token, sfId) {
  const { favoriteLocation } = decodeToken(token);
  localStorage.setItem('sfId', sfId);
  if (navigator.cookieEnabled)
    //updated max-age
    document.cookie = `access_token=${token}; path=/; ${Boolean(favoriteLocation) &&
      `max-age=${process.env.REACT_APP_COOKIE_TIME_TO_LIVE}`}`;
}

function removeToken() {
  localStorage.removeItem('sfId');
  if (navigator.cookieEnabled) document.cookie = `access_token=; path=/; max-age=0`;
}

async function clearCache() {
  return Promise.all([
    caches.delete('working-cache'),
    caches.delete('one-day-cache'),
    caches.delete('schedule-cache'),
    caches.delete('trainers-cache'),
    caches.delete('sessions-cache'),
    // caches.delete('b-cache'),
  ]);
}

export default {
  setToken,
  removeToken,
  getToken,
  decodeToken,
  clearCache,
  getNewOlSToken,
};
