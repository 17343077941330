import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadMessagesRequest: [],
  loadMessagesSuccess: ['messagesHistory', 'resentMessages'],
  loadMessagesFailure: ['error'],

  typeMessage: ['messageText'],
  selectTargetDialog: ['targetDialog'],
  clearTargetDialog: [],

  setTrainerId: ['trainerId'],
  clearMessagesState: [],

  filterMessagesHistory: ['targetDialogMessagesHistory'],
  updateMessages: [
    'messagesHistory',
    'targetDialog',
    'resentMessages',
    'targetDialogMessagesHistory',
  ],
  updateMessagesHistory: ['messagesHistory', 'resentMessages'],
  sendMessageRequest: [],
  sendMessageSuccess: ['message'],
  sendMessageFailure: ['error'],

  receiveMessage: ['data'],
  receiveMessageSuccess: ['messagesHistory', 'resentMessages'],
  receiveMessageSuccessWithUpdate: [
    'messagesHistory',
    'targetDialog',
    'resentMessages',
    'targetDialogMessagesHistory',
  ],
  receiveMessageFailure: ['error'],

  markMessagesAsRead: ['messagesHistory', 'targetDialogMessagesHistory'],

  receiveReadMessages: ['data'],
  receiveReadMessagesSuccess: ['messagesHistory', 'resentMessages'],
  receiveReadMessagesSuccessWithUpdate: [
    'messagesHistory',
    'targetDialogMessagesHistory',
    'resentMessages',
  ],
  receiveReadMessagesFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
