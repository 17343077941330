export default {
  menu: (styles, state) => ({
    ...styles,
    backgroundColor: `white`,
    borderRadius: '10px',
    boxShadow: 'none',
    border: '1px solid #d8d8d8',
    cursor: 'pointer',
    zIndex: 12,
  }),
  menuList: (styles, state) => ({
    ...styles,
    maxHeight: '200px',
  }),
  control: (styles, state) => ({
    ...styles,
    fontFamily: 'Montserrat-light',
    fontSize: '16px',
    borderRadius: '10px',
    cursor: 'pointer',
    lineHeight: '21px',
    marginTop: '5px',
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#000000',
      backgroundColor: `#ffffff`,
      fontFamily: 'Montserrat-light',
      fontSize: '16px',
      lineHeight: '21px',
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected && `#fe5000`,
        color: isSelected && `#ffffff`,
      },
      ':hover': {
        ...styles[':hover'],
        fontWeight: '600',
      },
    };
  },
  input: styles => ({
    ...styles,
    margin: '0',
    padding: '0',
    paddingLeft: '10px',
    color: `#000000`,
    fontFamily: 'Montserrat-light',
    fontSize: '16px',
    lineHeight: '21px',
  }),

  placeholder: styles => ({
    ...styles,
    margin: '0',
    padding: '0',
    paddingLeft: '10px',
    fontFamily: 'Montserrat-light',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'grey',
  }),
  noOptionsMessage: styles => ({
    ...styles,
    fontFamily: 'Montserrat-light',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#d8d8d8',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    marginRight: '15px',
  }),
};
