import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadClubsRequest: [],
  loadClubsSuccess: ['clubs'],
  loadClubsFailure: ['error'],

  loadSortedClubsRequest: ['clubs'],
  loadSortedClubsSuccess: ['clubs'],
  loadSortedClubsFailure: ['error'],

  loadRoomsRequest: ['clubId'],
  loadRoomsSuccess: ['rooms'],
  loadRoomsFailure: ['error'],

  loadClubRequest: ['clubName'],
  loadClubSuccess: ['club'],
  loadClubFailure: ['error'],
  selectTargetClub: ['club'],
});

const clubActions = {
  Types,
  Creators,
};

export default clubActions;
