import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { EditCard } from 'assets/icons';
import {
  AppBar,
  Box,
  Dialog,
  Fade,
  LinearProgress,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: '#FF5F1F',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: 'red',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const EditPayment = ({
  editNewCard,
  userPersonalInfo,
  userExistenceData,
  userExist,
  isMobile,
  card,
  url,
  newOlsUrl,
  onNext,
  loadBillingUrl,
  loadNewOlsBillingUrl,
  addCard,
  onBack,
}) => {
  const [cardInfo, setCardInfo] = useState({});
  const [Gatewayopen, setGatewayOpen] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setGatewayOpen(false);
  };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  function assignListeners() {
    window.addEventListener(
      'message',
      function listener(event) {
        if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
          return;
        }
        const {
          data: {
            cc_number,
            expiration_year,
            expiration_month,
            cardConnect_expiry,
            cc_merchant_unique_id,
            expiration_date,
            i4go_cardtype,
          },
        } = event;
        if (!isEmpty(cc_merchant_unique_id)) {
          window.messageReceived = true;
          setCardInfo({
            cc_number,
            expiration_year,
            expiration_month,
            cardConnect_expiry,
            cc_merchant_unique_id,
            expiration_date,
            i4go_cardtype,
          });
        }
      },
      false
    );
  }

  const openPayment = () => {
    setGatewayOpen(true);
    loadNewOlsBillingUrl();
    assignListeners();
  };
  const submitInfo = () => {
    const payload = {
      cardNumber: cardInfo.cc_number,
      isPrimary: true,
      i4goUniqueId: cardInfo.cc_merchant_unique_id,
      i4goCardType: cardInfo.i4go_cardtype,
      cardExpirationMonth: cardInfo.expiration_month,
      cardExpirationYear: cardInfo.expiration_year,
    };
    addCard(payload);
    setCardInfo(cardInfo);
    if (userExist) {
      const data = {
        data: {
          userId: JSON.stringify(userExistenceData?.data?.data),
          maskedCardNumber: cardInfo.cc_number,
          billingName: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
          nickname: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
          i4goUniqueId: cardInfo.cc_merchant_unique_id,
          cardExpirationMonth: cardInfo.expiration_month,
          cardExpirationYear: cardInfo.expiration_year,
        },
        dataForEdit: {
          nickname: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
        },
      };
      editNewCard(data);
      onNext();
    } else if (!userExist) {
      onNext();
    }
  };

  useEffect(() => {
    if (Object.keys(cardInfo).length !== 0) {
      submitInfo();
    }
  }, [cardInfo]);

  const handleEdit = async () => {
    openPayment();
  };

  const handleNext = () => {
    onNext();
  };

  return (
    <>
      <div className={`${styles.wrapper} ${isMobile ? styles.mob : ''}`}>
        <div className={styles.inputWrapper}>
          <h1 className={styles.heading}>My Primary Card</h1>
          <div className={styles.card}>
            <div className={styles.titleWrapper}>
              {/* <img className={styles.cardLogo} src={imgVisa} /> */}
              <img onClick={() => handleEdit()} src={EditCard} className={styles.edit} alt='edit icon'/>
            </div>
            <div className={styles.cardDetailsWrapper}>
              <p className={styles.cardDetails}>
                {userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName}
              </p>
              <p className={styles.cardDetails}>
                {`**** **** **** ${card?.cardNumber?.slice(-4)}`}
              </p>
              <p className={styles.cardDetails}>
                Exp:{' '}
                <span>{card?.cardExpirationMonth + '/' + card?.cardExpirationYear}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={Gatewayopen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              Update Card
            </Typography>
          </Toolbar>
        </AppBar>
        {newOlsUrl ? (
          <iframe
            title="payment"
            id="payment"
            src={`${newOlsUrl?.data}&parentUrl=${window.location.href}`}
            width="700px"
            style={{
              display: 'block',
              alignSelf: 'center',
              overflow: 'hidden',
              position: 'relative',
              marginLeft: '-20px',
              marginTop: '40px',
            }}
            scrolling="no"
            height={document.documentElement.clientHeight - 50}
          />
        ) : (
          <div className={classes.root}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          </div>
        )}
      </Dialog>
      <BackAndNextButton
        backButtonName="Back"
        nextButtonName="Next"
        onBack={onBack}
        onNext={() => handleNext()}
      />
      {/* {isMobile ? (
        <MobileFooter
          backButtonName="Back"
          NextButtonName="Next"
          onBack={onBack}
          onNext={() => handleNext()}
        />
      ) : (
        <Footer
          backButtonName="Back"
          nextButtonName="Next"
          onBack={onBack}
          onNext={() => handleNext()}
        />
      )} */}
    </>
  );
};

EditPayment.propTypes = {
  plan: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  loadNewOlsBillingUrl: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default EditPayment;
