import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/shared/Input';
import Checkbox from 'components/shared/Checkbox';
import styles from './style.module.css';

const Field = ({
  type,
  invalid,
  className,
  inputClassName,
  rightIcon,
  disabled,
  error,
  iconWidth,
  iconTop,
  boxShadow,
  fontFamily,
  ...props
}) => {
  const isCheckbox = type === 'checkbox';

  const inputProps = {
    ...props,
    type,
    error,
  };

  const renderInput = () => {
    if (isCheckbox) return <Checkbox {...inputProps} />;
    return (
      <Input
        iconWidth={iconWidth}
        boxShadow={boxShadow}
        fontFamily={fontFamily}
        iconTop={iconTop}
        disabled={disabled}
        rightIcon={rightIcon}
        type={type}
        invalid={invalid}
        className={inputClassName}
        {...inputProps}
      />
    );
  };

  return (
    <div className={`${styles.field} ${isCheckbox && styles.fieldCheckbox} ${className}`}>
      {renderInput()}
      <p
        className={`${styles.error} ${isCheckbox && styles.errorCheckbox}`}
        style={{ width: props?.width }}
      >
        {invalid && error && error}
      </p>
    </div>
  );
};

Field.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  rightIcon: PropTypes.string,
  iconWidth: PropTypes.string,
  fontFamily: PropTypes.string,
};

Field.defaultProps = {
  className: '',
  inputClassName: '',
  type: 'text',
  invalid: false,
  error: '',
  disabled: false,
  rightIcon: '',
  iconWidth: '',
  fontFamily: '',
};

export default Field;
