import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { ReactComponent as DropdownArrow } from 'assets/icons/DropdownArrow.svg';
import { ReactComponent as CheckMark } from 'assets/icons/check_mark.svg';
import selectStyles from './selectStyles';
import customOptionsStyles from './customOptionStyles';

const DropdownIndicator = ({ selectProps }) => {
  const { menuIsOpen } = selectProps;
  if (menuIsOpen)
    return (
      <DropdownArrow
        style={{
          width: '20px',
          marginRight: '20px',
          transform: 'rotate(180deg)',
          pointerEvents: 'none',
          marginTop: '2px',
        }}
      />
    );
  return (
    <DropdownArrow
      style={{ width: '20px', marginRight: '20px', pointerEvents: 'none' }}
    />
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

const CustomOption = props => {
  const { isSelected, label, innerProps, data } = props;
  const { isDisabled, withLabelClosed } = data;
  return isSelected ? (
    <div {...innerProps} style={customOptionsStyles}>
      {label}
      <CheckMark
        style={{
          width: '16px',
          marginRight: '20px',
          pointerEvents: 'none',
        }}
      />
    </div>
  ) : isDisabled ? (
    <div
      {...innerProps}
      onClick={() => {}}
      style={{ ...customOptionsStyles, cursor: 'auto', fontWeight: '300', color: 'grey' }}
    >
      {label}
      <span style={{ fontSize: 12, fontStyle: 'italic', paddingRight: 10 }}>closed </span>
    </div>
  ) : withLabelClosed ? (
    <div {...innerProps} style={{ ...customOptionsStyles, fontWeight: '300' }}>
      {label}
      <span style={{ fontSize: 12, fontStyle: 'italic', paddingRight: 5, color: 'grey' }}>
        closed
      </span>
    </div>
  ) : withLabelClosed ? (
    <div {...innerProps} style={{ ...customOptionsStyles, fontWeight: '300' }}>
      {label}
      <span style={{ fontSize: 12, fontStyle: 'italic', paddingRight: 5, color: 'grey' }}>
        closed
      </span>
    </div>
  ) : (
    <components.Option {...props} />
  );
};

CustomOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.object,
  isDisabled: PropTypes.bool,
};

CustomOption.defaultProps = {
  data: {},
  isDisabled: false,
};

const CustomSubscriptionOption = props => {
  const { isSelected, isDisabled, label, innerProps } = props;

  return isSelected ? (
    <div {...innerProps} style={customOptionsStyles}>
      {label}
      <CheckMark
        style={{
          width: '16px',
          marginRight: '20px',
          pointerEvents: 'none',
        }}
      />
    </div>
  ) : isDisabled ? (
    <div
      {...innerProps}
      onClick={() => {}}
      style={{ ...customOptionsStyles, cursor: 'auto', fontWeight: '300', color: 'grey' }}
    >
      {label}
    </div>
  ) : (
    <components.Option {...props} />
  );
};

CustomSubscriptionOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

CustomSubscriptionOption.defaultProps = {
  isDisabled: false,
};

const calculateCustomComponents = (hideIndicatorSeparator, isSubscriptionSelect) => {
  const standardSet = {
    DropdownIndicator,
    Option: isSubscriptionSelect ? CustomSubscriptionOption : CustomOption,
  };
  if (hideIndicatorSeparator) return { ...standardSet, IndicatorSeparator: () => null };
  return standardSet;
};

const ReactSelect = ({
  options,
  value,
  name,
  defaultValue,
  width,
  height,
  marginTop,
  marginBottom,
  borderRadius,
  boxShadow,
  backgroundColor,
  isSearchable,
  onChange,
  placeholder,
  isDisabled,
  hideIndicatorSeparator,
  hideBorder,
  isOptionDisabled,
  isSubscriptionSelect,
  boldValue,
  containerStyle,
  error,
  id='',
  invalid,
}) => {
  const getValue = options.filter(option => {
    if (value instanceof Array) {
      return value.includes(option.value);
    }
    return option.value === value;
  });

  const customComponents = calculateCustomComponents(
    hideIndicatorSeparator,
    isSubscriptionSelect
  );

  return (
    <div>
      <Select
        styles={{
          ...selectStyles,
          container: base => ({
            ...base,
            width: width || '220px',
            height: height || '40px',
            border: hideBorder ? 'none' : invalid ? '1px solid red' : '1px solid #d8d8d8',
            backgroundColor: backgroundColor || 'none',
            borderRadius: borderRadius || 'none',
            boxShadow: boxShadow || 'none',
            marginTop: marginTop || 'none',
            marginBottom: marginBottom || 'none',
            ...containerStyle,
            '&:focus': {
              outline: 'none',
            },
            '&:active': {
              outline: 'none',
            },
          }),
          singleValue: base => ({
            ...base,
            fontFamily: boldValue && 'Futura Heavy',
          }),
        }}
        components={customComponents}
        options={options}
        value={(getValue.length !== 0 ? getValue : options[defaultValue]) || null}
        name={name}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={onChange}
        style={{ width, height, fontWeight: 600 }}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled ? option => option.disabled : () => {}}
        error={error}
        id={id}
        invalid={invalid}
      />
      <p
        style={{
          boxSizing: 'border-box',
          height: 'auto',
          color: 'red',
          fontFamily: 'Futura Book',
          fontSize: '12px',
          fontWeight: 300,
          opacity: 0.7,
          paddingTop: '5px',
          lineHeight: '16px',
        }}
      >
        {invalid && error && error}
      </p>
    </div>
  );
};

ReactSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.string,
  backgroundColor: PropTypes.string,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  hideIndicatorSeparator: PropTypes.bool,
  hideBorder: PropTypes.bool,
  isOptionDisabled: PropTypes.bool,
  isSubscriptionSelect: PropTypes.bool,
  boldValue: PropTypes.bool,
  containerStyle: PropTypes.object,
};

ReactSelect.defaultProps = {
  options: [],
  value: '',
  name: 'select',
  defaultValue: '',
  placeholder: '',
  width: '220px',
  height: '50px',
  isSearchable: false,
  onChange: () => {},
  isDisabled: false,
  hideIndicatorSeparator: false,
  hideBorder: false,
  isOptionDisabled: false,
  isSubscriptionSelect: false,
  boldValue: false,
  containerStyle: {},
};

export default ReactSelect;
