import React from 'react';
import { roundLogo } from 'assets/logos';
import { arrowLeftBlack } from 'assets/icons';
import styles from './styles.module.css';

const NotFoundPageChatbot = () => {
  return (
    <div className={styles.container}>
      <img src={roundLogo} alt="" />
      <p>Session id expired</p>
      <a
        href="https://fitness-sf-demo-39c951e6fe0fc3a0ad966b3.webflow.io/"
      >
        <div className={styles.button}>
          <img src={arrowLeftBlack} alt="" />
          <p> Go back</p>
        </div>
      </a>
    </div>
  );
};

export default NotFoundPageChatbot;
