import { call, put, takeLeading, takeLatest, debounce } from 'redux-saga/effects';

import {
  isOnline,
  closeSocketConnection,
  openSocketConnection,
  getSocket,
} from 'utils/socket';
import vaccineStatuses from 'enums/vaccineStatuses';
import history from 'store/history';
import api from 'services/api';
import authUtils from 'utils/auth';
import actions from './actions';
import errorActions from '../error/actions';

const {
  Types: {
    LOGIN_REQUEST,
    LOGIN_WITH_SERVICE,
    RESET_PASSWORD_REQUEST,
    REQUEST_RESET_REQUEST,
    LOGOUT_REQUEST,
    LOAD_PROFILE_REQUEST,
    LOAD_MEMBERSHIP_REQUEST,
    CHANGE_FAVORITE_LOCATION_REQUEST,
    CHANGE_PROFILE_INFO_REQUEST,
    CHECK_FAMILY_REQUEST,
    UPDATE_PASSWORD_REQUEST,
    CHECK_MEMBER_STATUS_REQUEST,
    SET_VACCINE_STATUS_REQUEST,
  },
  Creators,
} = actions;

const {
  Creators: { setError },
} = errorActions;

function* loginWithService({ data }) {
  if (data?.error) {
    yield put(setError('Cannot find member, please check details and try again'));
  } else {
    authUtils.setToken(data.token, data.sfId);
    api.setAuthTokenToHeader(data.token);

    yield put(Creators.loginSuccess({ ...data }));
    const socket = getSocket();
    if (socket.disconnected) openSocketConnection();
    yield isOnline({ id: data.user.sfId });
  }
}

function* login({ payload }) {
  try {
    const { email, password, idCA } = payload;

    // if (navigator.cookieEnabled) yield call(authUtils.clearCache);

    const { data } = yield call(api.post, '/auth', {
      email,
      password,
      idCA,
    });

    authUtils.setToken(data.token, data.user.sfId);
    api.setAuthTokenToHeader(data.token);

    yield put(Creators.loginSuccess(data.user));
    const socket = getSocket();
    if (socket.disconnected) openSocketConnection();
    yield isOnline({ id: data.user.sfId });
  } catch (err) {
    console.log('err: ', err);

    const loginError = payload.idCA
      ? 'The username or password is incorrect'
      : 'The username or password is incorrect';

    const errorMessage =
      err?.response?.status === 404
        ? loginError
        : err?.response?.data?.message
        ? err?.response?.data?.message
        : "We're experiencing connectivity issues";

    if (err?.response?.status === 403) {
      yield put(setError(err?.response?.data?.message));
      yield put(Creators.loginFailure(''));
    } else yield put(Creators.loginFailure(errorMessage));
  }
}

function* logout() {
  try {
    yield call(authUtils.clearCache);

    authUtils.removeToken();
    yield put(Creators.logoutSuccess());
    yield closeSocketConnection();
  } catch (err) {
    yield put(Creators.logoutFailure(err));
  }
}

function* requestReset({ email }) {
  try {
    yield call(api.post, '/auth/request_reset_password', { email });
    yield put(Creators.requestResetSuccess());
    yield call(history.push, '/request_success');
  } catch (err) {
    const errorMessage =
      err.response.status === 404
        ? 'Cannot find this email'
        : 'Sorry, something went wrong, please try again in a minute or two';

    yield put(Creators.requestResetFailure(errorMessage));
  }
}

function* resetPassword({ payload }) {
  try {
    const { password } = payload;
    const resetToken = history.location.search.replace('?token=', '');

    const { data } = yield call(api.post, `/auth/reset_password`, {
      resetToken,
      password,
    });

    if (data.success) {
      yield put(Creators.resetPasswordSuccess());
      yield call(history.push, '/create_success');
    }
  } catch (err) {
    yield put(Creators.resetPasswordFailure(err));
    yield put(
      setError('Sorry, something went wrong, please try again in a minute or two')
    );
  }
}

function* updatePassword({ payload }) {
  try {
    const { data } = yield call(api.post, `/auth/update_password`, payload);
    if (data.success) {
      yield put(Creators.updatePasswordSuccess());
      yield put(setError('Your password was updated'));
    }
  } catch (err) {
    yield put(Creators.updatePasswordFailure(err));
    yield put(
      setError(
        'Sorry, could not update your password. Please try again in a minute or two.'
      )
    );
  }
}

export function* loadProfile() {
  try {
    const { data } = yield call(api.get, `/contacts/profile`);

    yield put(
      Creators.loadProfileSuccess({
        ...data,
        vaccineStatus: data.vaccineStatus
          ? data.vaccineStatus
          : vaccineStatuses.notYetUploaded,
      })
    );
  } catch (err) {
    yield put(Creators.loadProfileFailure(err));
    yield put(setError('Error, could not load your profile'));
  }
}

function* changeFavoriteLocation({ payload }) {
  try {
    const { data } = yield call(api.post, `/auth/favorite`, {
      favoriteLocation: payload,
    });

    const { token, user } = data;
    authUtils.setToken(token, user.sfId, false);
    api.setAuthTokenToHeader(token);

    yield put(Creators.changeFavoriteLocationSuccess(user));
  } catch (err) {
    yield put(Creators.changeFavoriteLocationFailure(err));
    yield put(setError('Hmm, could not change favorite location, try again please'));
  }
}

function* changeProfileInfo({ payload }) {
  try {
    const { data } = yield call(api.put, `/contacts/update_profile`, payload);
    yield put(Creators.changeProfileInfoSuccess(data));
  } catch (err) {
    yield put(Creators.changeProfileInfoFailure(err));
    yield put(
      setError(
        'Sorry, could not update your details, please try again in a minute or two'
      )
    );
  }
}

export function* loadMembership() {
  try {
    const { data } = yield call(api.get, `/contacts/membership`);
    yield put(Creators.loadMembershipSuccess(data.length ? data[0].userGroups : []));
  } catch (err) {
    yield put(Creators.loadMembershipFailure(err));
  }
}

function* checkFamily({ email }) {
  try {
    const { data } = yield call(api.get, `/auth/family?email=${email}`);
    yield put(Creators.checkFamilySuccess(data.isFamily));
  } catch (err) {
    yield put(Creators.checkFamilyFailure(err));
    yield put(
      setError('Sorry, something went wrong, please try again in a minute or two')
    );
  }
}

function* checkMemberStatus() {
  try {
    const { data } = yield call(api.get, `/auth/check-status`);
    yield put(Creators.checkMemberStatusSuccess(data));
  } catch (err) {
    yield put(Creators.checkMemberStatusFailure(err));
    yield put(setError('Sorry, could not fetch status'));
  }
}

function* setVaccineStatus({ payload }) {
  try {
    const { userGroupBody, vaccineStatusBody, isAuth } = payload;

    yield call(api.post, `/membership`, userGroupBody);
    yield call(api.post, `/contacts/vaccine-status`, vaccineStatusBody, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    yield put(Creators.setVaccineStatusSuccess());
    yield call(history.push, isAuth ? '/' : '/verify-status?m=ready');
  } catch (err) {
    yield put(Creators.setVaccineStatusFailure(err));
    yield put(setError('Please try again.'));
  }
}

export function* authRootSaga() {
  yield takeLeading(LOGIN_REQUEST, login);
  yield takeLeading(LOGIN_WITH_SERVICE, loginWithService);
  yield takeLeading(LOGOUT_REQUEST, logout);
  yield takeLatest(LOAD_PROFILE_REQUEST, loadProfile);
  yield takeLatest(LOAD_MEMBERSHIP_REQUEST, loadMembership);
  yield takeLatest(CHANGE_FAVORITE_LOCATION_REQUEST, changeFavoriteLocation);
  yield takeLatest(CHANGE_PROFILE_INFO_REQUEST, changeProfileInfo);
  yield takeLatest(SET_VACCINE_STATUS_REQUEST, setVaccineStatus);
  yield takeLeading(REQUEST_RESET_REQUEST, requestReset);
  yield takeLeading(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLeading(UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLeading(CHECK_MEMBER_STATUS_REQUEST, checkMemberStatus);

  yield debounce(1000, CHECK_FAMILY_REQUEST, checkFamily);
}
