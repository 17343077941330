import { connect } from 'react-redux';
import Actions from 'store/signUp/actions';
import clubsActions from 'store/clubs/actions';
import billingActions from 'store/billing/actions';
import RegistrationPageNew from 'pages/RegistrationPageNew';
import newOlsActions from 'store/newOls/actions';
import errorActions from 'store/error/actions';
import chatbotActions from 'store/chatbot/actions'

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    planInfo: state.signUp.planInfo,
    userExistenceData: state.signUp.userExistenceData,
    uploadedPhoto: state.signUp.uploadedPhoto,
    userPersonalInfo: state.signUp.userPersonalInfo,
    card: state.signUp.card,
    newUserInfo: state.signUp.newUserInfo,
    paymentDetails: state.signUp.paymentDetails,
    existingUserNewCard: state.signUp.existingUserNewCard,
    updatedUserDetails: state.signUp.updatedUserDetails,
    editedCardInfo: state.signUp.editedCardInfo,
    plans: state.signUp.plans,
    membershipPlanInfo: state.signUp.membershipPlanInfo,
    newOlsPlans: state.signUp.newOlsPlans,
    user: state.signUp.user,
    referralPerson: state.signUp.referralPerson,
    isRequesting: state.signUp.isRequesting,
    url: state.billing?.url?.url ? state.billing.url.url : '',
    newOlsUrl: state.billing.newOlsbilling,
    accessToken: state.newOls.accessToken,
    isRequestingEmail: state.signUp.isRequestingEmail,
    isRequestingNewUser: state.signUp.isRequestingNewUser,
    isRequestingUploadPhoto: state.signUp.isRequestingUploadPhoto,
    isRequestingUpdateExistingUser: state.signUp.isRequestingUpdateExistingUser,
    isChatbotRecordInserted: state.chatbot.sessionIdInfo,
    isChatbotRecordsUpdated: state.chatbot.chatbotRecords
  };
};
const mapDispatchToProps = {
  loadClubs: clubsActions.Creators.loadClubsRequest,
  loadSortedClubs: clubsActions.Creators.loadSortedClubsRequest,
  generateHashSaga: newOlsActions.Creators.generateHashRequest,
  getPlans: Actions.Creators.getPlansRequest,
  getNewOlsPlans: Actions.Creators.getNewOlsPlansRequest,
  getMembershipPlanInfo: Actions.Creators.getMembershipPlanInfoRequest,

  findUserByEmail: Actions.Creators.findUserByEmailRequest,
  uploadPhoto: Actions.Creators.uploadPhotoRequest,
  addUserPersonalInfo: Actions.Creators.addUserPersonalInfo,
  addCard: Actions.Creators.addCard,

  resetArrayState: Actions.Creators.resetArrayState,
  resetObjectState: Actions.Creators.resetObjectState,

  createNewUser: Actions.Creators.createNewUserRequest,
  getPaymentDetails: Actions.Creators.getPaymentDetailsRequest,

  addExistingUserNewCard: Actions.Creators.addExistingUserNewCardRequest,
  updateExistingUser: Actions.Creators.updateExistingUserRequest,
  editExistingUserCard: Actions.Creators.editExistingUserCardRequest,
  editNewCard: Actions.Creators.editNewCardRequest,
  addUserDetails: Actions.Creators.addUserDetailsRequest,
  // updateUser: Actions.Creators.updateUserRequest,
  loadBillingUrl: billingActions.Creators.loadBillingUrlRequest,
  loadNewOlsBillingUrl: billingActions.Creators.loadNewOlsBillingRequest,
  getPlanInfo: Actions.Creators.getPlanInfoRequest,
  checkReferralCode: Actions.Creators.checkReferralCodeRequest,
  setError: errorActions.Creators.setError,

  insertChatbotOlsRecord: chatbotActions.Creators.chatbotSessionIdRequest,
  updateChatbotRecords: chatbotActions.Creators.updateChatbotRecordRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPageNew);
