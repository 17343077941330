import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { isOnline } from 'utils/socket';
import { logger } from 'redux-logger';
import api from 'services/api';

import authUtils from 'utils/auth';
import history from './history';

import rootReducer from './reducer';
import rootSaga from './saga';
// import dxApi from 'services/dxApi';

const getInitialState = () => {
  const token = authUtils.getToken();

  if (token) {
    api.setAuthTokenToHeader(token);
    const {
      sfId,
      idCA,
      name,
      email,
      firstName,
      favoriteLocationName,
      favoriteLocation,
      favoriteLocationCAId,
      memberStatus,
      scanCode,
      isTrainer,
    } = authUtils.decodeToken(token);
    isOnline({ id: sfId });

    return {
      auth: {
        isAuth: true,
        user: {
          sfId,
          name,
          email,
          firstName,
          favoriteLocationName,
          favoriteLocation,
          favoriteLocationCAId,
          memberStatus,
          scanCode,
          isTrainer,
          idCA,
        },
      },
    };
  }

  return {
    auth: {
      isAuth: false,
    },
  };
};

const initialState = getInitialState();

const sagaMiddleware = createSagaMiddleware();

const middleWare =
  process.env.NODE_ENV !== 'production'
    ? [logger, routerMiddleware(history), sagaMiddleware]
    : [routerMiddleware(history), sagaMiddleware];

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
    : compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleWare))
);

sagaMiddleware.run(rootSaga);
export default store;
