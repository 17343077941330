import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { calculateStrength } from 'validation/auth.validation';
import styles from './passwordStrength.module.css';

const strengths = ['Weak', 'Medium', 'Strong'];

const PasswordStrength = ({ width, value }) => {
  const [strength, setStrength] = useState(-1);

  useEffect(() => {
    setStrength(calculateStrength(value));
  }, [value]);

  const getLevel = index => {
    switch (strength) {
      case 0:
        return index === 0 ? 'red' : '';
      case 1:
        return index === 0 || index === 1 || index === 2 ? 'yellow' : '';
      case 2:
        return 'green';
      case 3:
        return 'green';
      case 4:
        return 'green';
      default:
        return '';
    }
  };

  return (
    <div style={{ width }} className={styles.wrapper}>
      <div>
        <Text type="s2" className={styles.text}>
          Password strength:
        </Text>
        {strength >= 0 && (
          <>
            <Text
              type="s3"
              className={
                strength >= 2
                  ? styles.greenText
                  : strength >= 1
                  ? styles.yellowText
                  : styles.redText
              }
            >
              {strength > 2 ? strengths[2] : strengths[strength]}
            </Text>
          </>
        )}
      </div>
      <div className={styles.blocksWrapper}>
        {[1, 2, 3, 4, 5].map((el, i) => (
          <div className={`${styles.block} ${styles[getLevel(i)]}`} />
        ))}
      </div>
    </div>
  );
};

PasswordStrength.propTypes = {
  width: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

PasswordStrength.defaultProps = {};

export default PasswordStrength;
