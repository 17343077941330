import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  checkCallPermissionRequest: [],
  checkCallPermissionSuccess: ['friendId', 'username', 'credential'],
  checkCallPermissionFailure: ['error'],
  incomingCall: ['friendId', 'friendSocketId'],
  incomingCallRejected: [],
  clearCallState: [],
  clearIncomingCall: [],
  handleOpenCallPopUp: ['callId', 'friendName', 'isPopUpOpened'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
