import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { useHistory } from 'react-router-dom';
import {
  barbell,
  creditCards,
  takePhoto,
  personCircleOutline,
  tick,
  location2,
} from 'assets/icons';

const menuItems = [
  {
    id: 0,
    subId: 0,
    serialNo: '1',
    name: 'location',
    icon: location2,
  },
  {
    id: 1,
    subId: 1,
    serialNo: '2',
    name: 'membership',
    icon: barbell,
  },
  {
    id: 2,
    subId: 2,
    serialNo: '3',
    name: 'personal info',
    icon: personCircleOutline,
  },
  {
    id: 3,
    subId: 4,
    serialNo: '4',
    name: 'take photo',
    icon: takePhoto,
  },
  {
    id: 4,
    subId: 9,
    serialNo: '5',
    name: 'Add payment',
    icon: creditCards,
  },
  {
    id: 5,
    subId: 6,
    serialNo: '6',
    name: 'confirm & pay',
    icon: creditCards,
  },
];

const Menu = ({
  setTab,
  tab,
  setSection,
  setTitle,
  titles,
  isLocationValid,
  resetArrayState,
}) => {
  const history = useHistory();
  const setTabAndSection = (subId, id) => {
    setSection(subId);
    setTab(id);
  };
  const handleChangeTab = (id, subId) => {
    if (id === 0) {
      if (!isLocationValid) {
        setTitle(titles[id]);
        setTabAndSection(subId, id);
        resetArrayState('newOlsPlans');
      } else {
        setTitle(titles[id]);
        setTabAndSection(subId, id);
        resetArrayState('newOlsPlans');
        history.push('/')
      }
    } else if (id === 1) {
      setTitle(titles[id]);
      setTabAndSection(subId, id);
    } else if (id === 2) {
      setTitle(titles[id]);
      setTabAndSection(subId, id);
    } else if (id === 3) {
      setTitle(titles[4]);
      setTabAndSection(subId, id);
    } else if (id === 4) {
      setTitle(titles[8]);
      setTabAndSection(subId, id);
    } else if (id === 5) {
      setTitle(titles[6]);
      setTabAndSection(subId, id);
    } else {
      setTitle(null);
      setTabAndSection(subId, id);
    }
  };

  return (
    <>
      <div className={styles.menu}>
        {menuItems.map((el, i) => (
          <div className={styles.container} key={el.id}>
            <div className={`${styles.item} `}>
              {tab === i ? (
                <p className={`${styles.itemNo} ${tab >= i && styles.activeTab}`}>
                  <img
                    className={`${styles.activeTabIcon} ${
                      el.icon == location2 ? styles.locationIcon : ''
                    }`}
                    alt=''
                    src={el.icon}
                  />
                </p>
              ) : tab > i ? (
                <p
                  onClick={() => handleChangeTab(el.id, el.subId)}
                  className={`${styles.itemNo} ${tab >= i && styles.activeTabTick}`}
                >
                  <img
                    className={`${styles.tick} ${
                      // el.id === 1 ? '' :
                      styles.hover
                    }`}
                    alt=''
                    src={tick}
                  />
                </p>
              ) : (
                <p className={`${styles.itemNo}`}>{el.serialNo}</p>
              )}
            </div>
            <div
              id="ItemName"
              className={`${styles.itemName} ${tab === i && styles.activeTabName}`}
            >
              {el.name}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

Menu.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.number.isRequired,
};

export default Menu;
