import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';
import BackButton from 'layouts/RegistrationLayoutNew/Buttons/BackButton';

const TakePhotoSection = ({
  handleConvertPhotoIntoBinary,
  userExist,
  uploadPhoto,
  userExistenceData,
  isMobile,
  photo,
  takePhoto,
  onNext,
  onBack,
  editDetailsOnNext,
  handleEditDetailsOnNext,
}) => {


  const handleNextButton = () => {
    if (!userExist) {
      if (editDetailsOnNext === 2) {
        handleEditDetailsOnNext();
      } else {
        onNext();
      }
    } else if (userExist) {
      const blob = handleConvertPhotoIntoBinary(photo);
      const formData = new FormData();
      formData.append('profilePicture', blob, 'photo.jpg');
      uploadPhoto(userExistenceData, formData);
      if (editDetailsOnNext === 2) {
        handleEditDetailsOnNext();
      } else {
        onNext();
      }
    }
  };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <div className={styles.body}>
      <div className={`${styles.container} ${isMobile ? styles.mobile : ''}`}>
        {photo ? (
          <div className={styles.imgSection}>
            <img src={photo} className={styles.img} alt="photos" />
          </div>
        ) : (
          <Camera
            onTakePhoto={dataUri => {
              takePhoto(dataUri);
            }}
            idealFacingMode={FACING_MODES.USER}
            idealResolution={{ width: 350, height: 350 }}
            imageType={IMAGE_TYPES.JPG}
            sizeFactor={1}
          />
        )}

        <div className={`${styles.buttonsContainer} ${isMobile ? styles.mobile : ''}`}>
          <Button
            disabled={!photo}
            className={`${styles.retakeButton} ${isMobile ? styles.mobile : ''}`}
            onClick={() => takePhoto('')}
          >
            Retake
          </Button>
          <Button
            disabled={!photo}
            className={`${styles.nextButton} ${isMobile ? styles.mobile : ''}`}
            onClick={() => handleNextButton()}
          >
            Next
          </Button>
        </div>

        {isMobile ? (
          <>
            <BackAndNextButton
              backButtonName="Back"
              nextButtonName="Next"
              nextButtonDisable={!photo}
              onBack={onBack}
              onNext={() => handleNextButton()}
            />
          </>
        ) : (
          <BackButton onBack={onBack} backButtonName="Back" />
        )}
      </div>
    </div>
  );
};

TakePhotoSection.propTypes = {
  photo: PropTypes.string.isRequired,
  takePhoto: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default TakePhotoSection;
