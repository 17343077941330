import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  generateHashRequest: ['clubData'],
  generateHashSuccess: ['hmac'],
  generateHashFailure: ['error'],

  generateAccessTokenRequest: ['hash'],
  generateAccessTokenSuccess: ['accessToken'],
  generateAccessTokenFailure: ['error'],

  getGeoLocationRequest: [],
  getGeoLocationSuccess: ['geoLocationCoordinates'],
  getGeoLocationFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
