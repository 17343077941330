import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadBillingRequest: [''],
  loadBillingSuccess: ['billing'],
  loadBillingFailure: ['error'],

  loadNewOlsBillingRequest: [''],
  loadNewOlsBillingSuccess: ['newOlsbilling'],
  loadNewOlsBillingFailure: ['error'],

  makePrimaryRequest: ['payload'],
  makePrimarySuccess: [''],
  makePrimaryFailure: ['error'],

  makePaymentRequest: ['payload'],
  makePaymentSuccess: [''],
  makePaymentFailure: ['error'],

  saveCardInfoRequest: ['payload'],
  saveCardInfoSuccess: ['cardInfo'],
  saveCardInfoFailure: ['error'],

  addNewCardRequest: ['payload'],
  addNewCardSuccess: [''],
  addNewCardFailure: ['error'],

  deleteCardRequest: ['payload'],
  deleteCardSuccess: [''],
  deleteCardFailure: ['error'],

  loadBillingUrlRequest: ['payload'],
  loadBillingUrlSuccess: ['url'],
  loadBillingUrlFailure: ['error'],
});

const billingActions = {
  Types,
  Creators,
};

export default billingActions;
