import { call, put, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import errorActions from '../error/actions';
import dxApi from 'services/dxApi';

const {
  Types: {
    CHATBOT_SESSION_ID_REQUEST,
    UPDATE_CHATBOT_RECORD_REQUEST,
  },
  Creators,
} = actions;

const {
  Creators: { setError },
} = errorActions;


function* insertChatbotOlsRecord({ sessionId }) {
  try {
    const { data } = yield call(dxApi.post, `/api/ols/insert-chatbotols-record?sessionId=${sessionId}`);
    if (data.success === false) {
      yield put(Creators.chatbotSessionIdFailure(data.message));
      yield put(setError(data.message));
    } else {
      yield put(Creators.chatbotSessionIdSuccess(data.success))
    }
  } catch (err) {
    yield put(Creators.chatbotSessionIdFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* updateChatbotRecords({ records }) {
  try {
    const { data } = yield call(dxApi.post, `/api/ols/update-chatbotols-record`, records);
    if (data.success === false) {
      yield put(Creators.updateChatbotRecordFailure(data.message));
      yield put(setError(data.message));
    } else {
      yield put(Creators.updateChatbotRecordSuccess(data.success))
    }
  } catch (err) {
    yield put(Creators.updateChatbotRecordFailure(err));
    yield put(setError('Something went wrong'));
  }
}


export function* chatbotSaga() {
  yield takeLeading(CHATBOT_SESSION_ID_REQUEST, insertChatbotOlsRecord);
  yield takeLeading(UPDATE_CHATBOT_RECORD_REQUEST, updateChatbotRecords);
}