import { call, put, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import errorActions from '../error/actions';
import dx_api from 'services/dxApi';
import { loadSortedClubs } from 'store/clubs/sagas';
import dxApi from 'services/dxApi';
import authUtils from '../../utils/auth';

const {
  Types: {
    GENERATE_HASH_REQUEST,
    GENERATE_ACCESS_TOKEN_REQUEST,
    GET_GEO_LOCATION_REQUEST,
  },
  Creators,
} = actions;

const {
  Creators: { setError },
} = errorActions;

export async function getGeolocation() {
  return new Promise(async (resolve, reject) => {
    if ('geolocation' in navigator) {
      try {
        const position = await new Promise((innerResolve, innerReject) => {
          navigator.geolocation.getCurrentPosition(innerResolve, innerReject);
        });
        resolve(position.coords);
      } catch (error) {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
}

async function generateHash(key, body) {
  try {
    const encoder = new TextEncoder();
    const keyData = encoder.encode(key);
    const dataBuffer = encoder.encode(JSON.stringify(body));
    const importedKey = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: { name: 'SHA-1' } },
      false,
      ['sign']
    );
    const signatureBuffer = await crypto.subtle.sign('HMAC', importedKey, dataBuffer);
    const signatureArray = Array.from(new Uint8Array(signatureBuffer));
    const hmac = signatureArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hmac.toUpperCase();
  } catch (error) {
    console.error('Error creating HMAC:', error);
  }
}

function* generateAccessToken(hash) {
  try {
    let header = {
      'X-OLS-Signature': hash,
    };
    let body = {
      clientId: 'fitnesssf-api-clubdev',
      clientSecret: 'secret_text_iCB4P94NLwYefvY',
    };
    const token = yield call(dx_api.post, `/ols-account/generate-token`, body, {
      headers: header,
    });
    if (token) {
      document.cookie = `new_ols_access_token=${token.data.data.accessToken}; max-age=${process.env.REACT_APP_COOKIE_TIME_TO_LIVE}`;
      const DXtoken = authUtils.getNewOlSToken();
      if (DXtoken) {
        dxApi.setAuthTokenToHeader(DXtoken);
        let clubs = { zip: null };
        yield call(loadSortedClubs, { clubs });
      }
    }
    yield put(Creators.generateAccessTokenSuccess(token.data));
  } catch (error) {
    yield put(Creators.generateAccessTokenFailure(error));
  }
}

function* generateHashSaga({ clubData }) {
  try {
    let body = {
      clientId: 'fitnesssf-api-clubdev',
      clientSecret: 'secret_text_iCB4P94NLwYefvY',
    };
    const key = 'Q1VWQWXiNlUYEpc0qhulonSJCMNRmbqB';
    const hash = yield call(generateHash, key, body);
    yield put(Creators.generateHashSuccess(hash));
    if (hash) {
      yield call(generateAccessToken, hash, clubData);
    }
  } catch (error) {
    yield put(Creators.generateHashFailure(error));
  }
}

export function* newOlsSaga() {
  yield takeLeading(GENERATE_HASH_REQUEST, generateHashSaga);
  yield takeLeading(GENERATE_ACCESS_TOKEN_REQUEST, generateAccessToken);
  yield takeLeading(GET_GEO_LOCATION_REQUEST, getGeolocation);
}
