import axios from 'axios';

const localBaseUrl = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  timeout: 20000,
});

const newOlsUrl = axios.create({
  baseURL: process.env.REACT_APP_API_DOTNET_URI,
  timeout: 20000,
});

export { localBaseUrl, newOlsUrl };
