import { call, put, takeLeading } from 'redux-saga/effects';
import api from 'services/api';
import sortBy from 'lodash/sortBy';
import history from 'store/history';
import Actions from './actions';
import errorActions from '../error/actions';
import dxApi from 'services/dxApi';
import { getGeolocation } from 'store/newOls/sagas';
const {
  Types: {
    LOAD_CLUBS_REQUEST,
    LOAD_CLUB_REQUEST,
    LOAD_ROOMS_REQUEST,
    LOAD_SORTED_CLUBS_REQUEST,
  },
  Creators: {
    loadClubsSuccess,
    loadClubsFailure,

    loadRoomsSuccess,
    loadRoomsFailure,

    loadClubSuccess,
    loadClubFailure,

    loadSortedClubsSuccess,
    loadSortedClubsFailure,
  },
} = Actions;

const {
  Creators: { setError },
} = errorActions;

export function* loadClubs() {
  try {
    const { data } = yield call(api.get, `/clubs`);
    const sorted = sortBy(data, item => item.name);
    yield put(loadClubsSuccess(sorted));
  } catch (error) {
    yield put(loadClubsFailure(error));
    yield put(setError('Load clubs error.'));
  }
}

export function* loadSortedClubs({ clubs }) {
  try {
    const coordinates = yield call(getGeolocation);
    let apiData;
    if (coordinates != null) {
      apiData = {
        lat: JSON.stringify(coordinates.latitude),
        lng: JSON.stringify(coordinates.longitude),
        zip: clubs?.zip,
      };
    } else {
      apiData = {
        lat: null,
        lng: null,
        zip: clubs?.zip,
      };
    }
    const { data } = yield call(dxApi.post, `/api/ols/sort-locations`, apiData);
    yield put(loadSortedClubsSuccess(data.data));
  } catch (error) {
    yield put(loadSortedClubsFailure(error));
    yield put(setError('Load sorted clubs error.'));
  }
}

export function* loadClub({ clubName }) {
  try {
    const { data } = yield call(api.get, `/clubs/${clubName}`);
    if (!Array.isArray(data) || data.length !== 1) {
      yield put(loadClubFailure('club does not exist'));
      history.push('/locations');
    } else yield put(loadClubSuccess(data[0]));
  } catch (error) {
    history.push('/locations');
    yield put(loadClubFailure(error));
    yield put(setError('Load club error.'));
  }
}

export function* loadRooms({ clubId }) {
  try {
    const { data } = yield call(api.get, `/clubs/rooms/${clubId}`);
    yield put(loadRoomsSuccess(data));
  } catch (error) {
    yield put(loadRoomsFailure(error));
    yield put(setError('Failed to load rooms.'));
  }
}

export function* clubsSaga() {
  yield takeLeading(LOAD_CLUBS_REQUEST, loadClubs);
  yield takeLeading(LOAD_CLUB_REQUEST, loadClub);
  yield takeLeading(LOAD_ROOMS_REQUEST, loadRooms);
  yield takeLeading(LOAD_SORTED_CLUBS_REQUEST, loadSortedClubs);
}
