import { spawn } from 'redux-saga/effects';
import { authRootSaga } from './auth/sagas';
import { clubsSaga } from './clubs/sagas';
import { historySaga } from './history/sagas';
import { billingSaga } from './billing/sagas';
import { messagesSaga } from './messages/sagas';
import { sessionCallSaga } from './sessionCall/sagas';
import { signUpSaga } from './signUp/sagas';
import { newOlsSaga } from './newOls/sagas';
import { chatbotSaga } from './chatbot/sagas';

export default function*() {
  yield spawn(authRootSaga);
  yield spawn(clubsSaga);
  yield spawn(billingSaga);
  yield spawn(historySaga);
  yield spawn(messagesSaga);
  yield spawn(sessionCallSaga);
  yield spawn(signUpSaga);
  yield spawn(newOlsSaga);
  yield spawn(chatbotSaga);
}
