import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { RegistrationPageNew } from 'containers';
import Message from 'components/shared/Message';
import SmallMobileContext from 'context/SmallMobileContext';
import MobileContext from 'context/MobileContext';
import TabletContext from 'context/TabletContext';
import ScrollToTop from 'services/HOCs/ScrollToTop';
import MessageActions from 'store/messages/actions';
import ErrorActions from 'store/error/actions';
import SessionCallActions from 'store/sessionCall/actions';
import { getSocket } from 'utils/socket';
import NotFoundPage from './NotFoundPage';
import NotFoundPageChatbot from './NotFoundPageChatbot';
// import CongratulationsSection from './RegistrationNew/CongratulationsSection';
import PaymentConfirmationSection from './RegistrationNew/PaymentConfirmationSection';

const socket = getSocket();

const App = ({
  isAuth,
  sfId,
  isTrainer,
  receiveMessage,
  error,
  errorType,
  clearError,
  receiveReadMessages,
  incomingCall,
  incomingCallRejected,
}) => {
  const [isSmallMobile, setIsSmallMobile] = useState(
    document.documentElement.clientWidth <= 450
  );
  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth <= 768);
  const [isTablet, setIsTablet] = useState(document.documentElement.clientWidth <= 992);

  const handleResize = () => {
    const { clientWidth } = document.documentElement;
    setIsSmallMobile(clientWidth <= 450);
    setIsMobile(clientWidth <= 768);
    setIsTablet(clientWidth <= 991);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const body = document.getElementById('root');
    if (isAuth) body.className = 'logged-in';
    if (!isAuth) body.className = 'logged-out';
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) {
      if (!socket.hasListeners('message'))
        socket.on('message', message => {
          receiveMessage(message);
        });
      if (!socket.hasListeners('message read success'))
        socket.on('message read success', data => {
          receiveReadMessages(data);
        });
      socket.on('request-call', data => {
        incomingCall(data.friendId, data.friendSocketId);
      });
      if (!socket.hasListeners('stop-session-call'))
        socket.on('stop-session-call', () => {
          if (!isTrainer) incomingCallRejected();
        });
      if (!socket.hasListeners('peer-disconnected'))
        socket.on('peer-disconnected', () => {
          if (!isTrainer) incomingCallRejected();
        });
    }
  }, [isAuth, incomingCall, receiveMessage, receiveReadMessages]);

  useEffect(() => {
    if (sfId) {
      window.gtag('event', 'login', {
        event_label: `User sfId=${sfId} logged in`,
        event_category: 'login',
      });
      window.gtag('set', { user_id: sfId });
    }
  }, [sfId]);

  const locationNames = [
    'marin',
    'embarcadero',
    'fillmore',
    'transbay',
    'mid-market',
    'oakland',
    'soma',
    'castro',
  ];
  const currentPath = window.location.pathname.substring(1).split('/')[0];
  const isLocationValid = locationNames.includes(currentPath);

  return (
    <>
      <TabletContext.Provider value={isTablet}>
        <MobileContext.Provider value={isMobile}>
          <SmallMobileContext.Provider value={isSmallMobile}>
            <React.StrictMode>
              <ScrollToTop>
                <Switch>
                  {/* Location routes Logic */}
                  {locationNames.includes(currentPath) ? (
                    <>
                      <Route path="/:location" exact>
                        <RegistrationPageNew
                          isLocationValid={isLocationValid}
                          locationRoute={currentPath}
                        />
                      </Route>
                      <Route path="/:location/payment-confirmation" exact>
                        <PaymentConfirmationSection />
                      </Route>
                    </>
                  ) : (
                    <Route path="/" exact>
                      <RegistrationPageNew
                        isLocationValid={isLocationValid}
                        locationRoute="undefined"
                      />
                    </Route>
                  )}
                  <Route path="/expired.html" exact>
                    <NotFoundPageChatbot />
                  </Route>
                  <Route path="*">
                    <NotFoundPage />
                  </Route>
                </Switch>
              </ScrollToTop>
            </React.StrictMode>
          </SmallMobileContext.Provider>
        </MobileContext.Provider>
      </TabletContext.Provider>

      {error && (
        <Message type={errorType} text={error} timeout={5000} onClose={clearError} />
      )}
    </>
  );
};

App.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  email: PropTypes.string,
  sfId: PropTypes.string,
  receiveMessage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  errorType: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  favoriteLocation: PropTypes.string.isRequired,
  memberStatus: PropTypes.string.isRequired,
  membership: PropTypes.array.isRequired,
  receiveReadMessages: PropTypes.func.isRequired,
  loadMessages: PropTypes.func.isRequired,
  messagesHistory: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.array,
  ]).isRequired,
  incomingCall: PropTypes.func.isRequired,
  incomingCallRejected: PropTypes.func.isRequired,
  isTrainer: PropTypes.bool.isRequired,
  vaccineStatus: PropTypes.string,
};

App.defaultProps = {
  vaccineStatus: '',
};

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  email: state.auth?.user?.email,
  sfId: state.auth?.user?.sfId,
  isTrainer: state.auth?.user?.isTrainer ? state.auth.user.isTrainer : false,
  error: state.error.message,
  errorType: state.error.type,
  favoriteLocation: state.auth?.isAuth ? state.auth.user.favoriteLocation : '',
  memberStatus: state.auth?.isAuth ? state.auth.user.memberStatus : '',
  membership: state.auth?.membership || [],
  vaccineStatus: state.auth?.profile?.vaccineStatus,
  messagesHistory: state.messages.messagesHistory,
  hasIncomingCall: state.messages.hasIncomingCall,
});

const mapDispatchToProps = dispatch => {
  return {
    loadMessages: () => {
      dispatch(MessageActions.Creators.loadMessagesRequest());
    },
    receiveMessage: data => dispatch(MessageActions.Creators.receiveMessage(data)),
    receiveReadMessages: data =>
      dispatch(MessageActions.Creators.receiveReadMessages(data)),
    clearError: () => {
      dispatch(ErrorActions.Creators.clearError());
    },
    setMessage: data => {
      dispatch(ErrorActions.Creators.setMessage(data));
    },
    incomingCall: (friendId, friendSocketId) => {
      dispatch(SessionCallActions.Creators.incomingCall(friendId, friendSocketId));
    },
    incomingCallRejected: () => {
      dispatch(SessionCallActions.Creators.incomingCallRejected());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
