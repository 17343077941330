import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { CircularProgress } from '@mui/material';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';

const SubmitScreen = ({
  userExist,
  existingUserNewCard,
  handleConvertPhotoIntoBinary,
  userPersonalInfo,
  termsAccept,
  setTermsAccept,
  emailAddress,
  newUserInfo,
  setTitle,
  titles,
  setSection,
  setTab,
  selectedClub,
  selectedPlan,
  card,
  createNewUser,
  onNext,
  onBack,
  isMobile,
  userExistenceData,
  updateExistingUser,
  seteditDetailsOnNext,
  editedCardInfo,
  photo,
  paymentDetails,
  state,
  resetObjectState,
  updatedUserDetails,
  referralPerson,
  isRequestingNewUser,
  isRequestingUploadPhoto,
  isRequestingUpdateExistingUser,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const parts = userPersonalInfo?.birthday?.split('/');
  const [month, day, year] = parts;
  const history = useHistory();
  const { location } = useParams();


  var userDetailsForCreation = {
    onlyPreview: false,
    gender: userPersonalInfo?.gender,
    firstName: userPersonalInfo?.firstName,
    lastName: userPersonalInfo?.lastName,
    dateOfBirth: `${year}-${month}-${day}`,
    email: emailAddress,
    phoneNumber: parseInt(userPersonalInfo?.phone?.replace(/[^0-9]/g, '')),
    contactAddressLine1: userPersonalInfo?.address,
    contactCity: userPersonalInfo?.city,
    contactState: userPersonalInfo?.state,
    contactZip: userPersonalInfo?.zipCode,
    emergencyContactName: userPersonalInfo?.emergencyContactName,
    emergencyContactPhone: parseInt(
      userPersonalInfo?.emergencyContactPhone?.replace(/[^0-9]/g, '')
    ),
    homeClubId: parseInt(selectedClub?.clubautomationclubidC),
    membershipId: selectedPlan?.id,
    paymentMethod: 'credit card',
    eftAuthAccept: true,
    signature: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    billingAddressLine1: userPersonalInfo?.address,
    billingCity: userPersonalInfo?.city,
    billingState: userPersonalInfo?.state,
    billingZip: userPersonalInfo?.zipCode,
    memberPortalPassword: 'A9cebc648-2e9e-4c6d-8c7a-6ea5ff36d',
    termsAccept: termsAccept,
    waiverReleaseAccept: true,
    billingName: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    i4goUniqueId: card?.i4goUniqueId,
    maskedCardNumber: card?.cardNumber,
    cardExpirationMonth: parseInt(card?.cardExpirationMonth),
    cardExpirationYear: parseInt(card?.cardExpirationYear),
    referredByCaId: Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : '',
    referredByName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : '',
  };

  var userDetailsForUpdation = {
    onlyPreview: true,
    gender: userPersonalInfo?.gender,
    caID: JSON.stringify(userExistenceData?.data?.data),
    firstName: userPersonalInfo?.firstName,
    lastName: userPersonalInfo?.lastName,
    dateOfBirth: `${year}-${month}-${day}`,
    email: emailAddress,
    cellPhone: parseInt(userPersonalInfo?.phone?.replace(/[^0-9]/g, '')),
    contactAddressLine1: userPersonalInfo?.address,
    contactCity: userPersonalInfo?.city,
    contactState: userPersonalInfo?.state,
    contactZip: userPersonalInfo?.zipCode,
    emergencyContactName: userPersonalInfo?.emergencyContactName,
    emergencyContactPhone: parseInt(
      userPersonalInfo?.emergencyContactPhone?.replace(/[^0-9]/g, '')
    ),
    homeClubId: parseInt(selectedClub?.clubautomationclubidC),
    membershipId: selectedPlan?.id,
    paymentMethod: 'credit card',
    eftAuthAccept: true,
    signature: card?.billingName,
    billingAddressLine1: userPersonalInfo?.address,
    billingCity: userPersonalInfo?.city,
    billingState: userPersonalInfo?.state,
    billingZip: userPersonalInfo?.zipCode,
    memberPortalPassword: 'A9cebc648-2e9e-4c6d-8c7a-6ea5ff36d',
    termsAccept: termsAccept,
    waiverReleaseAccept: true,
    billingName: userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName,
    i4goUniqueId: card?.i4goUniqueId,
    maskedCardNumber: card?.cardNumber,
    cardExpirationMonth: parseInt(card?.cardExpirationMonth),
    cardExpirationYear: parseInt(card?.cardExpirationYear),
    paymentMethodId:
      Object.keys(editedCardInfo).length === 0
        ? JSON.stringify(existingUserNewCard?.data)
        : editedCardInfo?.data,
    referredByCaId: Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : '',
    referredByName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : '',
  };

  const handleSubmitPayment = async () => {
    if (!userExist) {
      setisLoading(true);
      const blob = handleConvertPhotoIntoBinary(photo);
      const formData = new FormData();
      formData.append('profilePicture', blob, 'photo.jpg');
      let data = {
        user: userDetailsForCreation,
        isPhotoUpload: true,
        photo: formData,
      };
      await createNewUser(data);
    } else if (userExist) {
      setisLoading(true);
      updateExistingUser(userDetailsForUpdation);
    }
  };

  const handleBack = () => {
    resetObjectState('paymentDetails');
    setTermsAccept(false);
    onBack();
  };

  const handleEdit = (tab, section) => {
    setTab(tab);
    setSection(section);

    if (tab === 2 && section === 2) {
      seteditDetailsOnNext(2);
      setTitle(titles[2]);
      resetObjectState('userExistenceData');
    }
    if (tab === 2 && section === 3) {
      seteditDetailsOnNext(3);
      setTitle(titles[3]);
    }
    if (tab === 1 && section === 1) {
      seteditDetailsOnNext(1);
      setTitle(titles[1]);
    }
    if (tab === 4 && section === 9) {
      seteditDetailsOnNext(9);
      setTitle(titles[8]);
    }
  };

  useEffect(() => {
    seteditDetailsOnNext(0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!userExist && Object.keys(newUserInfo).length !== 0) {
      onNext();
      localStorage.setItem('payment', 'completed');
      history.push({
        pathname: `/${location}/payment-confirmation`,
        state: { paymentDetails: newUserInfo, selectedClub },
      });
    }
    if (userExist && Object.keys(updatedUserDetails).length !== 0) {
      onNext();
      localStorage.setItem('payment', 'completed');
      history.push({
        pathname: `/${location}/payment-confirmation`,
        state: { paymentDetails, selectedClub },
      });
    }
  }, [newUserInfo, updatedUserDetails]);

  useEffect(() => {
    if (!userExist) {
      if (!isRequestingNewUser) {
        setisLoading(false);
      }
    } else {
      if (!isRequestingUpdateExistingUser) {
        setisLoading(false);
      }
    }
  }, [isRequestingNewUser, isRequestingUploadPhoto, isRequestingUpdateExistingUser]);

  return (
    <>
      {Object.keys(paymentDetails).length === 0 ? (
        <>
          <div className={styles.progressbarDiv}>
            <CircularProgress
              className={styles.circularProgress}
              style={{ color: '#fe5000' }}
            />
          </div>
        </>
      ) : (
        <>
          <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
            <div className={styles.row1}>
              <div className={`${styles.grid1} ${isMobile ? styles.mobile : ''}`}>
                <div className={styles.header}>
                  <p className={styles.mainHeading}>Email Address</p>
                  <button onClick={() => handleEdit(2, 2)} className={styles.editBtn}>
                    Edit
                  </button>
                </div>
                <p className={styles.mainContent}>{emailAddress}</p>
              </div>
              <div className={`${styles.grid3} ${isMobile ? styles.mobile : ''}`}>
                <div>
                  <div className={styles.header}>
                    <p className={styles.mainHeading}>Personal Info</p>
                    <button onClick={() => handleEdit(2, 3)} className={styles.editBtn}>
                      Edit
                    </button>
                  </div>
                  <div className={styles.PersonalContentWrapper}>
                    <p className={styles.contentHeading}>Full Name:</p>
                    <p className={styles.Personalcontent}>
                      {userPersonalInfo?.firstName + ' ' + userPersonalInfo?.lastName}
                    </p>
                  </div>
                  <div className={styles.PersonalContentWrapper}>
                    <p className={styles.contentHeading}>Phone Number:</p>
                    <p className={styles.Personalcontent}>{userPersonalInfo?.phone}</p>
                  </div>
                  <div className={styles.PersonalContentWrapper}>
                    <p className={styles.contentHeading}>Birthdate:</p>
                    <p className={styles.Personalcontent}>{month + '-' + day + '-' + year}</p>
                  </div>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.AddressWrapper}>
                  <p className={styles.addressHeading}>Home Address:</p>
                  <p className={styles.addressContent}>
                    {userPersonalInfo?.address + ' , ' + userPersonalInfo?.city + '.'}
                    <br />
                    {state + ' , ' + userPersonalInfo?.zipCode}
                  </p>
                </div>
              </div>
              <div className={`${styles.grid2} ${isMobile ? styles.mobile : ''}`}>
                <div className={styles.header}>
                  <p className={styles.mainHeading}>Payment Info</p>
                  <button onClick={() => handleEdit(4, 9)} className={styles.editBtn}>
                    Edit
                  </button>
                </div>
                <p className={styles.mainContent}>
                  <span>{`**** **** **** ${card?.cardNumber?.slice(-4)}`}</span>
                </p>
                <p className={styles.expiration_date}>
                  Exp Date : {card?.cardExpirationMonth + '/' + card?.cardExpirationYear}
                </p>
              </div>
            </div>
            <div className={styles.row2}>
              <div className={`${styles.grid4} ${isMobile ? styles.mobile : ''}`}>
                <div>
                  <div className={styles.header}>
                    <p className={styles.mainHeading}>Membership Plan</p>
                    <button onClick={() => handleEdit(1, 1)} className={styles.editBtn}>
                      Edit
                    </button>
                  </div>
                  <p style={{ padding: ' 0px 0px 0px 30px' }} className={styles.content}>
                    {paymentDetails?.data?.itemName}
                  </p>
                  <div className={styles.header}>
                    <div
                      style={{ display: 'flex', flexDirection: 'column', fontWeight: 0 }}
                    >
                      <p
                        style={{ textDecoration: 'none', lineHeight: '30px' }}
                        className={styles.mainHeading}
                      >
                        Monthly Dues
                      </p>
                      <p className={styles.text2}>billed 1st of every month</p>
                    </div>
                    <p style={{ marginTop: '7px' }} className={styles.text}>
                      {paymentDetails?.data?.enrollmentFee}
                    </p>
                  </div>
                  <div className={styles.membershipDivider}></div>
                </div>

                <div>
                  <div className={styles.totalAmtWrapper}>
                    <p
                      style={{
                        textDecoration: 'none',
                        padding: '0px 0px 0px 30px',
                        lineHeight: '70px',
                      }}
                      className={styles.mainHeading}
                    >
                      Due Today
                    </p>
                    <div
                      style={{ marginBottom: '15px', justifyContent: 'space-between' }}
                      className={styles.contentWrapper}
                    >
                      <p style={{}} className={styles.contentHeading}>
                        Prorated 1st month
                      </p>
                      <p className={styles.content}>
                        {paymentDetails?.data?.membershipFee}
                      </p>
                    </div>
                    <div
                      style={{ marginBottom: '15px', justifyContent: 'space-between' }}
                      className={styles.contentWrapper}
                    >
                      <p className={styles.contentHeading}>Last month</p>

                      <p className={styles.content}>
                        {paymentDetails?.data?.enrollmentFee}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className={styles.totalAmtHeading}>Total</p>
                      <p className={styles.totalAmtContent}>
                        {paymentDetails?.data?.totalDue}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mainScroll} />
          <BackAndNextButton
            onBack={() => handleBack()}
            backButtonName="Back"
            nextButtonName={
              isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                'Submit Payment'
              )
            }
            nextButtonDisable={isLoading}
            onNext={() => handleSubmitPayment()}
          />
        </>
      )}
    </>
  );
};

SubmitScreen.propTypes = {
  selectedClub: PropTypes.object.isRequired,
  selectedPlan: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  planInfo: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default SubmitScreen;
