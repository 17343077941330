import React from 'react';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import PropTypes from 'prop-types';

const NextButton = ({ onNext, disabled, nextButtonName }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Button
            disabled={disabled}
            width={'300px'}
            className={styles.button}
            onClick={onNext}
          >
            {nextButtonName}
          </Button>
        </div>
      </div>
    </>
  );
};

NextButton.propTypes = {
  onBack: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nextButtonName: PropTypes.string,
};
export default NextButton;
