import moment from 'moment';
import momentTZ from 'moment-timezone';

export const getSFTime = option => {
  return option === 'full'
    ? momentTZ.tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm')
    : momentTZ.tz('America/Los_Angeles').format('YYYY-MM-DD');
};

export const formatDateToTimezone = (classDate, option) => {
  const userTimezone = momentTZ.tz.guess(true);
  let formatDate = moment(classDate);
  if (momentTZ.tz(userTimezone).format('z') !== 'PDT') {
    if (option === 'full') formatDate = momentTZ(formatDate).tz('America/Los_Angeles');
    else
      formatDate = momentTZ(formatDate)
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD');
  }
  return formatDate;
};
