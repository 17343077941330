import { takeLatest, call, put, fork } from 'redux-saga/effects';
import api from 'services/api';
import billingActions from './actions';
import errorActions from '../error/actions';
import dxApi from 'services/dxApi';

const {
  Types: {
    LOAD_BILLING_REQUEST,
    LOAD_BILLING_URL_REQUEST,
    MAKE_PRIMARY_REQUEST,
    ADD_NEW_CARD_REQUEST,
    DELETE_CARD_REQUEST,
    MAKE_PAYMENT_REQUEST,
    SAVE_CARD_INFO_REQUEST,
    LOAD_NEW_OLS_BILLING_REQUEST,
  },
  Creators: {
    loadBillingSuccess,
    loadBillingFailure,
    makePrimarySuccess,
    loadBillingUrlSuccess,
    loadBillingUrlFailure,
    addNewCardSuccess,
    makePrimaryFailure,
    addNewCardFailure,
    deleteCardSuccess,
    deleteCardFailure,
    makePaymentSuccess,
    makePaymentFailure,
    saveCardInfoSuccess,
    saveCardInfoFailure,
    loadNewOlsBillingSuccess,
    loadNewOlsBillingFailure,
  },
} = billingActions;

const {
  Creators: { setError },
} = errorActions;

function* loadBilling() {
  try {
    const { data } = yield call(api.get, `/billing`);
    const { balanceData, paymentData } = data;
    const payData = paymentData.data.creditCards;
    const balData = balanceData.data;
    yield put(loadBillingSuccess({ payData, balData }));
  } catch (error) {
    yield put(loadBillingFailure(error));
    yield put(setError('Load billing error.'));
  }
}

function* makePrimary(payload) {
  try {
    const { billingAddressLine1, billingCity, billingState, billingZip, id } = payload;
    yield call(
      api.put,
      `/billing?cardId=${id}&billingAddressLine1=${billingAddressLine1}&billingCity=${billingCity}&billingState=${billingState}&billingZip=${billingZip}`
    );
    yield put(makePrimarySuccess());
  } catch (error) {
    yield put(makePrimaryFailure(error));
    yield put(setError('Make primary error.'));
  }
}

function* makePayment(payload) {
  try {
    yield call(api.post, `/billing/make-payment`, payload);
    yield put(makePaymentSuccess());
    yield put(setError('Thank you for your payment.'));
  } catch (error) {
    yield put(makePaymentFailure(error));
    yield put(setError('Make payment error.'));
  }
}

function* addNewCard(payload) {
  try {
    yield call(api.post, `/payment-methods/credit-card`, payload);
    yield put(addNewCardSuccess());
  } catch (error) {
    yield put(addNewCardFailure(error));
    yield put(setError('Add new card error.'));
  }
}

function* saveCardInfo({ payload }) {
  try {
    yield put(saveCardInfoSuccess(payload));
  } catch (error) {
    yield put(saveCardInfoFailure(error));
    yield put(setError('Save card info error.'));
  }
}

function* deleteCard(payload) {
  try {
    yield call(api.remove, `/payment-methods/credit-card?cardId=${payload}`);
    yield put(deleteCardSuccess());
  } catch (error) {
    yield put(deleteCardFailure(error));
    yield put(setError('Delete card error.'));
  }
}

function* loadBillingUrl() {
  try {
    const { data } = yield call(api.get, `/billing/url`);   
    // window.open(`${data.url}&parentUrl=${window.location.href}`, '_blank');
    yield put(loadBillingUrlSuccess(data));
  } catch (error) {
    yield put(loadBillingUrlFailure(error));
    yield put(setError('Load billingUrl error.'));
  }
}

function* loadNewOlsBillingUrl() {
  try {
    const { data } = yield call(dxApi.get, `/api/ols/billing/url`);
    // window.open(`${data.url}&parentUrl=${window.location.href}`, '_blank');
    yield put(loadNewOlsBillingSuccess(data));
  } catch (error) {
    yield put(loadNewOlsBillingFailure(error));
    yield put(setError('Load billingUrl error.'));
  }
}

export function* billingSaga() {
  yield takeLatest(LOAD_BILLING_REQUEST, loadBilling);
  yield takeLatest(SAVE_CARD_INFO_REQUEST, saveCardInfo);
  yield takeLatest(LOAD_BILLING_URL_REQUEST, loadBillingUrl);
    yield takeLatest(LOAD_NEW_OLS_BILLING_REQUEST, loadNewOlsBillingUrl);


  yield takeLatest(MAKE_PRIMARY_REQUEST, function* flow({ payload }) {
    yield call(makePrimary, payload);
    yield fork(loadBilling);
  });

  yield takeLatest(ADD_NEW_CARD_REQUEST, function* flow({ payload }) {
    yield call(addNewCard, payload);
    yield fork(loadBilling);
  });

  yield takeLatest(DELETE_CARD_REQUEST, function* flow({ payload }) {
    yield call(deleteCard, payload);
    yield fork(loadBilling);
  });

  yield takeLatest(MAKE_PAYMENT_REQUEST, function* flow({ payload }) {
    yield fork(makePayment, payload);
    yield fork(loadBilling);
  });
}
