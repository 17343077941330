import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './style.module.css';
import { CircularProgress } from '@mui/material';
import MobileContext from 'context/MobileContext';
import Header from './Header';

const PaymentConfirmationSection = () => {
  const isMobile = useContext(MobileContext);
  // const { location: gymName } = useParams();
  const history = useHistory();
  const location = useLocation();
  const paymentStatus = localStorage.getItem('payment');
  const [paymentDetails, setPaymentDetails] = useState(
    location.state?.paymentDetails?.newUserData
      ? location.state?.paymentDetails?.newUserData
      : location.state?.paymentDetails?.data
  );
  const [selectedClub, SetSelectedCLub] = useState(location.state?.selectedClub);
  const backgroundStyle = {
    backgroundImage: `${
      isMobile
        ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%)'
        : 'linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)'
    }, url(${isMobile ? selectedClub?.olsBgMobile : selectedClub?.olsBgDesktop})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    width: '100%',
    height: isMobile ? '100vh' : '120vh',
    alignItems: 'center',
  };

  useEffect(() => {
    if (paymentStatus === 'completed') {
      const timer = setTimeout(() => {
        window.location.replace('/');
        localStorage.setItem('payment', 'unCompleted');
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (!paymentDetails) {
      window.location.replace('/');
      localStorage.setItem('payment', 'unCompleted');
    } else {
      history.replace({
        pathname: location.pathname,
        state: {},
      });
    }
  }, [paymentDetails]);

  return (
    <>
      {!paymentDetails ? (
        <>
          <div className={styles.progressbarDiv}>
            <CircularProgress
              className={styles.circularProgress}
              style={{ color: '#fe5000' }}
            />
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className={styles.wrapper}>
            <div style={{ ...backgroundStyle }}>
              <div
                className={`${styles.overlayContent} ${isMobile ? styles.mobile : ''}`}
              >
                <h1
                  className={`${styles.welcomeHeading} ${
                    isMobile ? styles.mobile : ''
                  }  `}
                >
                  Thanks for Joining {isMobile ? <br /> : ''} FITNESS SF!
                </h1>
                <p
                  className={`${styles.welcomeSubheading} ${
                    isMobile ? styles.mobile : ''
                  }`}
                >
                  You'll receive a confirmation email {isMobile ? <br /> : ''} shortly
                  with next steps.
                </p>
                <div className={styles.row2}>
                  <div className={`${styles.grid4} ${isMobile ? styles.mobile : ''}`}>
                    {!isMobile ? (
                      <>
                        <div className={styles.firstHeadingContainer}>
                          <p className={styles.firstHeading}>Your Purchase Summary:</p>
                        </div>
                        <div className={styles.firstDivider}></div>
                      </>
                    ) : (
                      ''
                    )}
                    <div className={styles.planContainer}>
                      <p
                        className={`${styles.mainHeading} ${
                          isMobile ? styles.mobile : ''
                        }`}
                      >
                        Membership Plan
                      </p>
                      <p className={styles.content}>{paymentDetails?.itemName}</p>
                    </div>
                    <div className={styles.header}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontWeight: 0,
                        }}
                      >
                        <p
                          style={{ textDecoration: 'none', lineHeight: '30px' }}
                          className={`${styles.duesHeading} ${
                            isMobile ? styles.mobile : ''
                          }`}
                        >
                          Monthly Dues
                        </p>
                        <p className={styles.text2}>billed 1st of every month</p>
                      </div>
                      <p
                        style={{ marginTop: '7px' }}
                        className={`${styles.text} ${isMobile ? styles.mobile : ''}`}
                      >
                        {paymentDetails?.enrollmentFee}
                      </p>
                    </div>
                    <div
                      className={`${styles.membershipDivider} ${
                        isMobile ? styles.mobile : ''
                      }`}
                    ></div>

                    <div
                      className={`${styles.totalAmtWrapper} ${
                        isMobile ? styles.mobile : ''
                      }`}
                    >
                      {isMobile ? (
                        <p
                          style={{
                            textDecoration: 'none',
                            padding: '25px 0px 0px 30px',
                          }}
                          className={styles.text3}
                        >
                          Due Today
                        </p>
                      ) : (
                        ''
                      )}
                      <div
                        style={
                          isMobile
                            ? {
                                marginTop: '20px',
                                justifyContent: 'space-between',
                              }
                            : {
                                marginTop: '25px',
                                justifyContent: 'space-between',
                              }
                        }
                        className={styles.contentWrapper}
                      >
                        <p className={styles.contentHeading}>Prorated 1st month</p>
                        <p className={styles.content}>{paymentDetails?.membershipFee}</p>
                      </div>
                      <div
                        style={{ marginBottom: '5px', justifyContent: 'space-between' }}
                        className={styles.contentWrapper}
                      >
                        <p className={styles.contentHeading}>Last month</p>

                        <p className={styles.content}>{paymentDetails?.enrollmentFee}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p className={styles.totalAmtHeading}>Total Paid</p>
                        <p className={styles.totalAmtContent}>
                          {paymentDetails?.totalDue}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentConfirmationSection;
