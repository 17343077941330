import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { buddyReferral, buddyReferralCloseIcon } from 'assets/icons';
import styles from './style.module.css';
import MobileContext from 'context/MobileContext';

const ReferralPopup = ({ open, onClose, name }) => {
  const isMobile = useContext(MobileContext)
  return (
    <div>
      <Modal
        className={styles.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={3} className={styles.paper}>
            <div className={styles.closeIcon}>
              <img onClick={() => onClose()} src={buddyReferralCloseIcon} alt='close icon'/>
            </div>
            <img alt="You are invited" src={buddyReferral} />
            <div className={styles.content}>
              <b>{name}</b> has invited {!isMobile ? '' : <br />} you to join
              {isMobile ? '' : <br />} FITNESS SF.
              <p>
                Receive a $10 credit towards {!isMobile ? '' : <br />} future
                {isMobile ? '' : <br />} membership dues after {!isMobile ? '' : <br />}
                joining.
              </p>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

ReferralPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReferralPopup;
