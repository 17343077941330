const awsS3Url = process.env.REACT_APP_AMAZON_S3_STORAGE_URL;

export const roundLogo = `${awsS3Url}/logos/round_logo_f8clhb.svg`;
export const imgVisa = `${awsS3Url}/logos/img_visa_pnmnof.png`;
export const imgMastercard = `${awsS3Url}/logos/img_mastercard_k1cvpl.png`;
export const imgLogo = `${awsS3Url}/logos/img_logo_fszuv8.png`;
export const horizontalLogo = `${awsS3Url}/logos/horizontal_logo.svg`;
export const imgAmex = `${awsS3Url}/logos/img_amex_skj2ev.png`;
export const imgDiscover = `${awsS3Url}/logos/img_discover_mmvgyd.png`;
export const fsfLogo = `${awsS3Url}/logos/fsf-logo_hagqus.png`;
