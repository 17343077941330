/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import styles from './style.module.css';
import { buddy_referral_congratulations } from 'assets/icons';

const BuddyReferralHeader = ({ referralPerson }) => {
  console.log(referralPerson, 'Referral person');
  return (
    <>
      <div className={styles.wrapper}>
        <img className={styles.icon} src={buddy_referral_congratulations} alt=''/>
        <p>You’re using {referralPerson?.name} referral link.</p>
      </div>
    </>
  );
};

export default BuddyReferralHeader;
