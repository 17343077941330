import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { error } from 'assets/icons';
import styles from './style.module.css';

const AgeValidationPopup = ({ open, onClose, name }) => {
  return (
    <div>
      <Modal
        className={styles.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={3} className={styles.paper}>
            <>
              <img alt="error" src={error} className={styles.image} />
              <p className={styles.content}>
                <p>You must be 18 years or</p>
                <p>older to become a</p>
                <p>member.</p>
              </p>
            </>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

AgeValidationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default AgeValidationPopup;
