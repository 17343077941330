import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadHistoryRequest: ['payload'],
  loadHistorySuccess: ['history'],
  loadHistoryFailure: ['error'],
});

const historyActions = {
  Types,
  Creators,
};

export default historyActions;
