import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  loadClubsIsRequesting: false,
  targetClubIsRequesting: false,
  clubs: [],
  rooms: null,
  activeLocationClub: [],
  targetClub: null,
  error: null,
};

const loadClubsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loadClubsIsRequesting: true,
    error: null,
  };
};
const loadSortedClubsRequest = (state = INITIAL_STATE, { clubs }) => {
  return {
    ...state,
    clubs,
    loadClubsIsRequesting: false,
    error: null,
  };
};
const loadClubsSuccess = (state = INITIAL_STATE, { clubs }) => {
  const activeClub = clubs.filter(club => club && club.totalCapacity > 0);
  return {
    ...state,
    loadClubsIsRequesting: false,
    activeLocationClub: activeClub,
    clubs,
  };
};
const loadClubsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    loadClubsIsRequesting: false,
    error,
  };
};

const selectTargetClub = (state = INITIAL_STATE, { club }) => {
  return {
    ...state,
    targetClub: club,
  };
};

const loadClubRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    targetClubIsRequesting: true,
    error: null,
  };
};
const loadClubSuccess = (state = INITIAL_STATE, { club }) => {
  return {
    ...state,
    targetClubIsRequesting: false,
    targetClub: club,
  };
};
const loadClubFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    targetClubIsRequesting: false,
    error,
  };
};

const loadRoomsRequest = (state = INITIAL_STATE, { rooms }) => {
  return {
    ...state,
    targetClubIsRequesting: true,
    rooms,
  };
};

const loadRoomsSuccess = (state = INITIAL_STATE, { rooms }) => {
  return {
    ...state,
    targetClubIsRequesting: false,
    rooms,
  };
};
const loadRoomsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    targetClubIsRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_CLUBS_REQUEST]: loadClubsRequest,
  [Types.LOAD_CLUBS_SUCCESS]: loadClubsSuccess,
  [Types.LOAD_CLUBS_FAILURE]: loadClubsFailure,

  [Types.LOAD_CLUB_REQUEST]: loadClubRequest,
  [Types.LOAD_CLUB_SUCCESS]: loadClubSuccess,
  [Types.LOAD_CLUB_FAILURE]: loadClubFailure,

  [Types.LOAD_ROOMS_REQUEST]: loadRoomsRequest,
  [Types.LOAD_ROOMS_SUCCESS]: loadRoomsSuccess,
  [Types.LOAD_ROOMS_FAILURE]: loadRoomsFailure,

  [Types.LOAD_SORTED_CLUBS_REQUEST]: loadClubsRequest,
  [Types.LOAD_SORTED_CLUBS_SUCCESS]: loadSortedClubsRequest,
  [Types.LOAD_SORTED_CLUBS_FAILURE]: loadClubsFailure,

  [Types.SELECT_TARGET_CLUB]: selectTargetClub,
};

export default createReducer(INITIAL_STATE, HANDLERS);
