import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

import styles from './styles.module.css';

const PasswordInput = ({
  styleType,
  name,
  value,
  invalid,
  className,
  width,
  placeholder,
  hasvalue,
  ...props
}) => {
  return (
    <div className={styles.wrapper} style={{ width: width || '300px' }}>
      <div className={styles.icon}>
        <SearchIcon style={{ width: '15px', pointerEvents: 'none' }} />
      </div>
      <input
        className={styles.input}
        name={name}
        id={name}
        placeholder={placeholder}
        {...props}
        type="text"
        value={value}
      />
    </div>
  );
};

PasswordInput.propTypes = {
  type: PropTypes.string,
  styleType: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalid: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  hasvalue: PropTypes.string.isRequired,
};

PasswordInput.defaultProps = {
  type: 'text',
  styleType: '',
  name: '',
  value: '',
  invalid: false,
  className: '',
  width: '300px',
  placeholder: 'Search',
};

export default PasswordInput;
