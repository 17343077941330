import { createReducer } from 'reduxsauce';
import vaccineStatuses from 'enums/vaccineStatuses';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: true,
  requestType: '',
  isAuth: false,
  user: null,
  profile: null,
  error: null,
  isFamily: false,
  checkFamilyIsRequesting: false,
  checkFamilyError: null,
};

const request = (state = INITIAL_STATE, { type }) => {
  return { ...state, isRequesting: true, requestType: type, error: null };
};

const failure = (state = INITIAL_STATE, { type, error }) => {
  return {
    ...state,
    isRequesting: false,
    requestType: type,
    error,
  };
};

const loginSuccess = (state = INITIAL_STATE, { type, user }) => {
  return {
    ...state,
    user,
    isRequesting: false,
    isAuth: true,
    requestType: type,
    isFamily: false,
  };
};

const logoutSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
    isAuth: false,
    user: null,
  };
};

const requestResetSuccess = (state = INITIAL_STATE, { type }) => {
  return {
    ...state,
    isRequesting: false,
    requestType: type,
  };
};

const updatePasswordSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
  };
};

const resetPasswordSuccess = (state = INITIAL_STATE, { type }) => {
  return {
    ...state,
    isRequesting: false,
    requestType: type,
  };
};

const loadProfileSuccess = (state = INITIAL_STATE, { type, profile }) => {
  return {
    ...state,
    profile,
    isRequesting: false,
    requestType: type,
  };
};

const checkMemberStatusSuccess = (state = INITIAL_STATE, { memberStatus }) => {
  const cloneUser = { ...state.user };
  cloneUser.memberStatus = memberStatus;
  return {
    ...state,
    user: { ...cloneUser },
    isRequesting: false,
  };
};

const changeFavoriteLocationSuccess = (state = INITIAL_STATE, { user, type }) => {
  return {
    ...state,
    user,
    isRequesting: false,
    requestType: type,
  };
};

const changeProfileInfoSuccess = (state = INITIAL_STATE, { profile }) => {
  return {
    ...state,
    profile,
    isRequesting: false,
  };
};

const setVaccineStatusSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      vaccineStatus: vaccineStatuses.pendingConfirmation,
    },
    isRequesting: false,
  };
};

const loadMembershipSuccess = (state = INITIAL_STATE, { membership, type }) => {
  return {
    ...state,
    membership,
    isRequesting: false,
    requestType: type,
  };
};

const loadMembershipFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    membership: [],
    error,
  };
};

const checkFamilyRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFamily: false,
    checkFamilyIsRequesting: true,
    checkFamilyError: null,
  };
};

const checkFamilySuccess = (state = INITIAL_STATE, { isFamily }) => {
  return {
    ...state,
    isFamily,
    checkFamilyIsRequesting: false,
    checkFamilyError: null,
  };
};

const checkFamilySuccessFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    checkFamilyIsRequesting: false,
    checkFamilyError: error,
  };
};

const clearAuthError = (state = INITIAL_STATE) => {
  return {
    ...state,
    error: null,
    requestType: '',
  };
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: failure,

  [Types.LOGOUT_REQUEST]: request,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: failure,

  [Types.REQUEST_RESET_REQUEST]: request,
  [Types.REQUEST_RESET_SUCCESS]: requestResetSuccess,
  [Types.REQUEST_RESET_FAILURE]: failure,

  [Types.RESET_PASSWORD_REQUEST]: request,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: failure,

  [Types.UPDATE_PASSWORD_REQUEST]: request,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAILURE]: failure,

  [Types.LOAD_PROFILE_REQUEST]: request,
  [Types.LOAD_PROFILE_SUCCESS]: loadProfileSuccess,
  [Types.LOAD_PROFILE_FAILURE]: failure,

  [Types.SET_VACCINE_STATUS_REQUEST]: request,
  [Types.SET_VACCINE_STATUS_SUCCESS]: setVaccineStatusSuccess,
  [Types.SET_VACCINE_STATUS_FAILURE]: failure,

  [Types.LOAD_MEMBERSHIP_REQUEST]: request,
  [Types.LOAD_MEMBERSHIP_SUCCESS]: loadMembershipSuccess,
  [Types.LOAD_MEMBERSHIP_FAILURE]: loadMembershipFailure,

  [Types.CHANGE_FAVORITE_LOCATION_REQUEST]: request,
  [Types.CHANGE_FAVORITE_LOCATION_SUCCESS]: changeFavoriteLocationSuccess,
  [Types.CHANGE_FAVORITE_LOCATION_FAILURE]: failure,

  [Types.CHANGE_PROFILE_INFO_REQUEST]: request,
  [Types.CHANGE_PROFILE_INFO_SUCCESS]: changeProfileInfoSuccess,
  [Types.CHANGE_PROFILE_INFO_FAILURE]: failure,

  [Types.SET_VACCINE_STATUS_REQUEST]: request,
  [Types.SET_VACCINE_STATUS_SUCCESS]: setVaccineStatusSuccess,
  [Types.SET_VACCINE_STATUS_FAILURE]: failure,

  [Types.CLEAR_AUTH_ERROR]: clearAuthError,

  [Types.CHECK_FAMILY_REQUEST]: checkFamilyRequest,
  [Types.CHECK_FAMILY_SUCCESS]: checkFamilySuccess,
  [Types.CHECK_FAMILY_FAILURE]: checkFamilySuccessFailure,

  [Types.CHECK_MEMBER_STATUS_SUCCESS]: checkMemberStatusSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
