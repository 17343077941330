import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  allow: false,
  isRequesting: false,
  isPopUpOpened: false,
  hasIncomingCall: false,
  callId: '',
  friendId: '',
  friendName: '',
  friendSocketId: '',
  username: '',
  credential: '',
  error: null,
};

const clearIncomingCall = (state = INITIAL_STATE) => {
  return {
    ...state,
    hasIncomingCall: false,
  };
};
const clearCallState = (state = INITIAL_STATE) => {
  return {
    ...INITIAL_STATE,
  };
};
const checkCallPermissionRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const checkCallPermissionSuccess = (
  state = INITIAL_STATE,
  { friendId, username, credential }
) => {
  return {
    ...state,
    friendId,
    username,
    credential,
    isRequesting: false,
    allow: true,
  };
};
const checkCallPermissionFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    friendId: null,
    error,
  };
};
const incomingCall = (state = INITIAL_STATE, { friendId, friendSocketId }) => {
  return {
    ...state,
    friendId,
    friendSocketId,
    hasIncomingCall: true,
  };
};
const incomingCallRejected = (state = INITIAL_STATE) => {
  return {
    ...state,
    friendId: '',
    friendSocketId: '',
    friendName: '',
    hasIncomingCall: false,
  };
};
const handleOpenCallPopUp = (
  state = INITIAL_STATE,
  { callId, friendName, isPopUpOpened }
) => {
  return {
    ...state,
    callId,
    friendName,
    isPopUpOpened,
  };
};
export const HANDLERS = {
  [Types.CHECK_CALL_PERMISSION_REQUEST]: checkCallPermissionRequest,
  [Types.CHECK_CALL_PERMISSION_SUCCESS]: checkCallPermissionSuccess,
  [Types.CHECK_CALL_PERMISSION_FAILURE]: checkCallPermissionFailure,
  [Types.INCOMING_CALL]: incomingCall,
  [Types.INCOMING_CALL_REJECTED]: incomingCallRejected,
  [Types.CLEAR_CALL_STATE]: clearCallState,
  [Types.CLEAR_INCOMING_CALL]: clearIncomingCall,
  [Types.HANDLE_OPEN_CALL_POP_UP]: handleOpenCallPopUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
