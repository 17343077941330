import React from 'react';
import PropTypes from 'prop-types';
import { emailIcon as email } from 'assets/icons';
import styles from './styles.module.css';
import PasswordInput from './PasswordInput';
import SearchInput from './SearchInput/index';

const styleTypes = {};

const Input = ({
  type,
  styleType,
  name,
  value,
  invalid,
  className,
  width,
  placeholder,
  icon,
  rightIcon,
  disabled,
  iconWidth,
  iconTop,
  boxShadow,
  fontFamily,
  ...props
}) => {
  const classNames = `${styles.input} ${styleTypes[styleType]} ${className} ${invalid &&
    styles.invalid} ${icon && styles.inputPadding} ${rightIcon &&
    styles.inputWithRightIcon}`;
  return (
    <>
      {type === 'password' ? (
        <PasswordInput
          name={name}
          id={name}
          value={value}
          width={width}
          hasvalue={value?.length > 0 ? 'true' : 'false'}
          placeholder={placeholder}
          invalid={invalid}
          icon={icon}
          {...props}
        />
      ) : type === 'search' ? (
        <SearchInput
          name={name}
          id={name}
          value={value}
          width={width}
          hasvalue={value?.length > 0 ? 'true' : 'false'}
          placeholder={placeholder}
          {...props}
        />
      ) : (
        <div className={styles.wrapper1}>
          {icon && (
            <img
              src={icon === 'email' ? email : ''}
              className={styles.inputIcon}
              alt=""
            />
          )}
          <input
            disabled={disabled}
            className={classNames}
            type={type}
            name={name}
            id={name}
            value={value}
            style={{ width, boxShadow: boxShadow, fontFamily: fontFamily }}
            hasvalue={`${value}`?.length > 0 ? 'true' : 'false'}
            {...props}
          />

          <label
            className={`${styles.formLabel} ${icon && styles.inputWithIcon}`}
            htmlFor={name}
          >
            {placeholder}
          </label>

          {rightIcon && (
            <img
              src={rightIcon}
              style={{ width: iconWidth, top: iconTop }}
              className={styles.rightIcon}
              alt=""
            />
          )}
        </div>
      )}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  styleType: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalid: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  rightIcon: PropTypes.string,
  disabled: PropTypes.bool,
  iconWidth: PropTypes.string,
  iconTop: PropTypes.string,
  boxShadow: PropTypes.string,
  fontFamily: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  styleType: '',
  name: '',
  value: '',
  invalid: false,
  className: '',
  width: '360px',
  placeholder: '',
  icon: '',
  rightIcon: '',
  disabled: false,
  iconWidth: '',
  iconTop: '',
  boxShadow: '',
  fontFamily: '',
};

export default Input;
