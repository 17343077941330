import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from 'assets/icons/close.svg';

import styles from './style.module.css';

const Message = ({ type, text, timeout, onClose, left }) => {
  setTimeout(() => {
    onClose();
  }, timeout);

  return (
    <div
      className={[
        styles.container,
        left ? styles.containerLeft : styles.containerRight,
        type === 'message' && styles.message,
        type === 'login' && 'login-form__error-message',
      ].join(' ')}
    >
      <span className={styles.text}>{text}</span>

      <div className={styles.icon} onClick={onClose}>
        <Close
          style={{
            filter:
              'invert(99%) sepia(0%) saturate(2073%) hue-rotate(180deg) brightness(98%) contrast(99%)',
            width: '13px',
            height: '13px',
          }}
        />
      </div>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  timeout: PropTypes.number,
  onClose: PropTypes.func,
  left: PropTypes.bool,
};

Message.defaultProps = {
  text: '',
  type: '',
  timeout: 10,
  onClose: () => {},
  left: true,
};

export default Message;
