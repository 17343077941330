/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import inputStyles from 'components/shared/Input/styles.module.css';
import styles from './style.module.css';
import MobileContext from 'context/MobileContext';
const GeoLocationInput = ({
  value,
  name,
  label,
  invalid,
  className,
  width,
  error,
  inputClassName,
  rightIcon,
  onClickIcon,
  onChange,
  ...props
}) => {
  const labelRef = useRef(null);
  const isMobile = useContext(MobileContext);
  const [isHideLabel, setHideLabel] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (labelRef.current && !labelRef.current.contains(event.target))
        setHideLabel(false);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [labelRef, isHideLabel]);

  return (
    <div className={`${styles.field} ${isMobile ? styles.mob : ''} ${className}`}>
      {rightIcon && (
        <img
          src={rightIcon}
          onClick={onClickIcon}
          className={styles.rightIconTextfield}
          alt=""
        />
      )}
      <InputMask
        type="tel"
        name={name}
        className={`${styles.input} ${invalid && inputStyles.invalid} ${
          styles.inputMask
        } ${invalid && styles.invalid} ${inputClassName}`}
        invalid={invalid}
        error={error}
        onChange={onChange}
        value={value}
        hasvalue={value?.length > 0 ? 'true' : 'false'}
        style={{ width }}
        {...props}
      />
      <label
        className={`${inputStyles.formLabel} ${styles.label} ${isHideLabel &&
          styles.hideLabel}`}
        onClick={() => setHideLabel(true)}
        ref={labelRef}
        htmlFor={name}
      >
        {label}
      </label>

      {/* <p className={`${styles.error}`} style={{ width }}>
        {invalid && error && error}
      </p> */}
    </div>
  );
};

GeoLocationInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  width: PropTypes.string,
  rightIcon: PropTypes.string,
  onClickIcon: PropTypes.func,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  error: PropTypes.string,
};

GeoLocationInput.defaultProps = {
  value: '',
  name: '',
  label: '',
  rightIcon: '',
  onClick: null,
  onChange: null,
  className: '',
  inputClassName: '',
  width: '',
  invalid: false,
  error: '',
};

export default GeoLocationInput;
