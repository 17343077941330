import { call, put, takeLeading } from 'redux-saga/effects';
import { Base64 } from 'js-base64';
import api from 'services/api';
import moment from 'moment';
import actions from './actions';
import errorActions from '../error/actions';
import dx_api from 'services/dxApi';
import dxApi from 'services/dxApi';

const {
  Types: {
    GET_PLANS_REQUEST,
    GET_MEMBERSHIP_PLAN_INFO_REQUEST,
    GET_NEW_OLS_PLANS_REQUEST,
    GET_PLAN_INFO_REQUEST,
    CREATE_NEW_USER_REQUEST,
    CREATE_GUEST_REQUEST,
    ADD_USER_DETAILS_REQUEST,
    CHECK_REFERRAL_CODE_REQUEST,
    FIND_USER_BY_EMAIL_REQUEST,
    UPDATE_EXISTING_USER_REQUEST,
    UPLOAD_PHOTO_REQUEST,
    ADD_EXISTING_USER_NEW_CARD_REQUEST,
    EDIT_EXISTING_USER_CARD_REQUEST,
    EDIT_NEW_CARD_REQUEST,
    GET_PAYMENT_DETAILS_REQUEST,
    CREATE_USER_REQUEST,
  },
  Creators,
} = actions;

const {
  Creators: { setError },
} = errorActions;

//new OLS
function* getNewOlsPlans({ locationId }) {
  try {
    const { data } = yield call(
      dx_api.post,
      `/api/ols/get-plans-details?ClubautomationId=${locationId}`
    );
    yield put(Creators.getNewOlsPlansSuccess(data.data));
  } catch (err) {
    yield put(Creators.getNewOlsPlansFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* getMembershipPlanInfo({ locationName }) {
  try {
    const { data } = yield call(
      dx_api.post,
      `api/ols/get-location-plans-details?LocationName=${locationName}`
    );
    yield put(Creators.getMembershipPlanInfoSuccess(data));
  } catch (err) {
    yield put(Creators.getMembershipPlanInfoFailure(err));
    yield put(setError('Something went wrong'));
  }
}

//old OLS
function* getPlans({ locationId }) {
  try {
    const { data } = yield call(api.get, `/sign-up/plans?locationId=${locationId}`);
    yield put(Creators.getPlansSuccess(data));
  } catch (err) {
    yield put(Creators.getPlansFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* createUser({ info }) {
  try {
    const { data } = yield call(api.post, `/sign-up/create-user`, info);
    if (data.error) {
      yield put(Creators.createUserFailure(data.error));
      yield put(setError(data.error));
    } else yield put(Creators.createUserSuccess(data));
  } catch (err) {
    yield put(Creators.createUserFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* findUserByEmail(email) {
  try {
    const { data } = yield call(dx_api.post, `/api/ols/check-user-exists`, {
      email: email.email,
    });

    if (!data.success) {
      yield put(Creators.findUserByEmailFailure(data.message));
      yield put(setError(data.message));
    } else {
      yield put(Creators.findUserByEmailSuccess({ ...email, data }));
    }
  } catch (err) {
    yield put(Creators.findUserByEmailFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* uploadPhoto({ caId, info }) {
  try {
    const { data } = yield call(dx_api.post, `/api/ols/upload-photo?caId=${caId}`, info);
    if (data.error) {
      yield put(Creators.uploadPhotoFailure(data.error));
      yield put(setError(data.error));
    } else yield put(Creators.uploadPhotoSuccess(data));
  } catch (err) {
    yield put(Creators.uploadPhotoFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* createNewUser({ user }) {
  const userInfo = user.user;
  const isPhotoUpload = user.isPhotoUpload;
  try {
    // Create User API called
    const { data } = yield call(dx_api.post, `/api/ols/create-user`, userInfo);
    //if create user API success is false
    if (data.success === false) {
      yield put(Creators.createNewUserFailure(data.message));
      yield put(setError(data.message));
    } else {
      // If create user API success is true and isisPhotoUpload is true
      if (isPhotoUpload === true) {
        const caId = parseInt(data?.data?.userId);
        const info = user?.photo;
        // Upload photo API is Called
        const data2 = yield call(dx_api.post, `/api/ols/upload-photo?caId=${caId}`, info);
        //if upload photo API success is false
        if (data2?.data?.success === false) {
          yield put(Creators.createNewUserFailure(data.message));
          yield put(setError(data.message));
        } else {
          //if upload photo API success is true
          yield put(
            Creators.createNewUserSuccess({
              newUserData: data?.data,
              uploadPhotoData: data2?.data?.data,
            })
          );
        }
      }
    }
  } catch (err) {
    yield put(Creators.createNewUserFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* getPaymentDetails({ user }) {
  try {
    const { data } = yield call(dx_api.post, `/api/ols/create-user`, user?.user);
    if (data.error) {
      yield put(Creators.getPaymentDetailsFailure(data.error));
      yield put(setError(data.error));
    } else yield put(Creators.getPaymentDetailsSuccess(data));
    if (data?.status === 400 && data.success === false) {
      yield put(setError(data.message));
    }
    if (user.isPhotoUpload === true && data.status === 200) {
      let photoData = yield call(uploadPhoto, data?.data?.userId, user?.photo);
      yield put(Creators.uploadPhotoSuccess(photoData));
    }
  } catch (err) {
    yield put(Creators.getPaymentDetailsFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* addExistingUserNewCard({ card }) {
  try {
    const { data } = yield call(dx_api.post, `/api/ols/insert-card-details`, card);
    if (data.error) {
      yield put(Creators.addExistingUserNewCardFailure(data.error));
      yield put(setError(data.error));
    } else {
      yield put(Creators.addExistingUserNewCardSuccess(data));
    }
  } catch (err) {
    yield put(Creators.addExistingUserNewCardFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* editExistingUserCard({ card }) {
  try {
    const { data } = yield call(dx_api.put, `/api/ols/credit-card`, card);
    if (data.error) {
      yield put(Creators.editExistingUserCardFailure(data.error));
      yield put(setError(data.error));
    } else yield put(Creators.editExistingUserCardSuccess(data));
  } catch (err) {
    yield put(Creators.editExistingUserCardFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* editNewCard({ card }) {
  try {
    const { data } = yield call(dx_api.post, `/api/ols/insert-card-details`, card?.data);
    if (data.error) {
      yield put(Creators.editNewCardFailure(data.error));
      yield put(setError(data.error));
    } else {
      let card;
      yield put(Creators.editNewCardSuccess(data));
      if (data) {
        let creditCardId = data.data;
        card = {
          nickname: card?.dataForEdit?.nickname,
          creditCardId: JSON.stringify(creditCardId),
        };
        yield call(editExistingUserCard, { card });
      }
    }
  } catch (err) {
    yield put(Creators.editNewCardFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* getPlanInfo({ info }) {
  try {
    const { data } = yield call(api.post, `/sign-up/get-plan-info`, info);
    if (data.error) {
      yield put(Creators.getPlanInfoFailure(data.error));
      yield put(setError(data.error));
    } else yield put(Creators.getPlanInfoSuccess({ ...info, isExist: false }, data));
  } catch (err) {
    yield put(Creators.getPlanInfoFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* addUserDetails({ user }) {
  try {
    const { data } = yield call(api.get, `/sign-up/check-user?email=${user?.email}`);
    if (data) yield put(setError('Email already exist'));
    yield put(Creators.addUserDetailsSuccess({ ...user, isExist: data }));
  } catch (err) {
    yield put(Creators.addUserDetailsFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* updateExistingUser({ user }) {
  try {
    const { data } = yield call(dx_api.put, `/api/ols/update-user`, user);
    if (data.success === false) {
      yield put(Creators.updateExistingUserFailure(data.message));
      yield put(setError(data.message));
    } else {
      yield put(Creators.updateExistingUserSuccess(data));
    }
  } catch (err) {
    yield put(Creators.updateExistingUserFailure(err));
    yield put(setError('Something went wrong'));
  }
}

function* createGuest({ info }) {
  try {
    const { data } = yield call(api.post, `/sign-up/create-guest`, info);
    if (typeof data === 'string') {
      yield put(Creators.createGuestFailure(data));
      yield put(setError(data));
    } else yield put(Creators.createGuestSuccess(data));
  } catch (err) {
    yield put(Creators.createGuestFailure(err));
  }
}

function* checkReferralCode({ code, isReferral = true }) {
  const urlParams = new URLSearchParams(code);
  const validReferral = urlParams.get('m');
  try {
    if (code?.includes('code')) {
      const promoCode = Base64.decode(code?.split('=')[1]);
      const promoCodeArr = promoCode?.split('=');
      const isValidPromoCode = moment(promoCodeArr[2]).isBetween(
        moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        moment()
          .endOf('month')
          .format('YYYY-MM-DD')
      );
      if (isValidPromoCode) {
        const { data } = yield call(
          api.get,
          `/sign-up/check-referral-code?plan=${promoCodeArr[1]?.split('&')[0]}`
        );
        if (data.error) yield put(setError("Referral code don't exist"));
        yield put(Creators.checkPromoCodeSuccess(data));
      } else {
        yield put(setError("Referral code don't available"));
      }
    } else {
      const { data } = yield call(
        dxApi.get,
        `/api/ols/buddy-referral?member=${code?.split('=')[1]}`
      );
      if (data.success === false && validReferral !== null) {
        yield put(setError(data.message));
      } else if (data?.data) {
        yield put(Creators.checkReferralCodeSuccess(data?.data));
      }
    }
  } catch (err) {
    yield put(Creators.checkReferralCodeFailure(err));
  }
}

export function* signUpSaga() {
  yield takeLeading(GET_PLANS_REQUEST, getPlans);
  yield takeLeading(GET_NEW_OLS_PLANS_REQUEST, getNewOlsPlans);
  yield takeLeading(FIND_USER_BY_EMAIL_REQUEST, findUserByEmail);
  yield takeLeading(UPLOAD_PHOTO_REQUEST, uploadPhoto);
  yield takeLeading(CREATE_NEW_USER_REQUEST, createNewUser);
  yield takeLeading(ADD_EXISTING_USER_NEW_CARD_REQUEST, addExistingUserNewCard);
  yield takeLeading(EDIT_NEW_CARD_REQUEST, editNewCard);
  yield takeLeading(GET_PLAN_INFO_REQUEST, getPlanInfo);
  yield takeLeading(ADD_USER_DETAILS_REQUEST, addUserDetails);
  yield takeLeading(CREATE_GUEST_REQUEST, createGuest);
  yield takeLeading(CHECK_REFERRAL_CODE_REQUEST, checkReferralCode);
  yield takeLeading(UPDATE_EXISTING_USER_REQUEST, updateExistingUser);
  yield takeLeading(EDIT_EXISTING_USER_CARD_REQUEST, editExistingUserCard);
  yield takeLeading(GET_PAYMENT_DETAILS_REQUEST, getPaymentDetails);
  yield takeLeading(CREATE_USER_REQUEST, createUser);
  yield takeLeading(GET_MEMBERSHIP_PLAN_INFO_REQUEST, getMembershipPlanInfo);
}
