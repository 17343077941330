import React from 'react';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import { useContext } from 'react';
import { arrowLeft } from 'assets/icons';
const BackButton = ({ onBack, backButtonDisable, backButtonName }) => {
  const isPlanBackButton = backButtonName !== 'Back';
  const isMobile = useContext(MobileContext);
  return (
    <>
      <div className={`${styles.wrapper} ${isMobile ? styles.mob : null}`}>
        <div className={styles.innerWrapper}>
          <Button
            disabled={backButtonDisable}
            className={`${isPlanBackButton ? styles.newBtn : styles.btn} ${
              isMobile ? styles.mob : ''
            }`}
            onClick={onBack}
          >
            {isPlanBackButton ? (
              <>
                <img className={styles.arrowIcon} src={arrowLeft} alt="back button" />
                {backButtonName}
              </>
            ) : !isMobile && !isPlanBackButton ? (
              <>
                <img className={styles.arrowIcon} src={arrowLeft} alt="back button" />
                {backButtonName}
              </>
            ) : (
              <> {backButtonName}</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
  backButtonName: PropTypes.string,
};
export default BackButton;
