/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Dialog,
  Fade,
  LinearProgress,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.css';
import { isEmpty } from 'lodash';
import Field from 'components/shared/Field';
import { imgDiscover } from 'assets/logos';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: '#FF5F1F',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: 'red',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const AddPayment = ({
  addExistingUserNewCard,
  userExist,
  isMobile,
  userExistenceData,
  url,
  loadBillingUrl,
  addCard,
  onNext,
  onBack,
  newOlsUrl,
  loadNewOlsBillingUrl,
}) => {
  const [cardInfo, setCardInfo] = useState({});
  const [Gatewayopen, setGatewayOpen] = React.useState(false);
  const [billingName, setbillingName] = useState('');
  const classes = useStyles();

  const handleClose = () => {
    setGatewayOpen(false);
    addCard({});
    onBack();
  };

  function assignListeners() {
    window.addEventListener(
      'message',
      function listener(event) {
        if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
          return;
        }
        const {
          data: {
            cc_number,
            expiration_year,
            expiration_month,
            cardConnect_expiry,
            cc_merchant_unique_id,
            expiration_date,
            i4go_cardtype,
          },
        } = event;
        if (!isEmpty(cc_merchant_unique_id)) {
          window.messageReceived = true;
          setCardInfo({
            cc_number,
            expiration_year,
            expiration_month,
            cardConnect_expiry,
            cc_merchant_unique_id,
            expiration_date,
            i4go_cardtype,
          });
        }
      },
      false
    );
  }

  const openPayment = () => {
    setGatewayOpen(true);
    loadNewOlsBillingUrl();
    assignListeners();
  };

  useEffect(() => {
    openPayment();
    window.scrollTo(0, 0);
  }, []);

  const payload = {
    cardNumber: cardInfo.cc_number,
    isPrimary: true,
    i4goUniqueId: cardInfo.cc_merchant_unique_id,
    i4goCardType: cardInfo.i4go_cardtype,
    cardExpirationMonth: cardInfo.expiration_month,
    cardExpirationYear: cardInfo.expiration_year,
  };

  const submitInfo = () => {
    setCardInfo(cardInfo);
    const status = userExistenceData?.data?.status;
    if (status === 404) {
      addCard(payload);
      onNext();
    } else if (status === 200) {
      setGatewayOpen(false);
    }
  };

  const handleNext = () => {
    if (userExist) {
      addCard({ ...payload, billingName });
      const data = {
        userId: JSON.stringify(userExistenceData?.data?.data),
        maskedCardNumber: cardInfo?.cc_number,
        billingName: billingName,
        nickname: billingName,
        i4goUniqueId: cardInfo.cc_merchant_unique_id,
        cardExpirationMonth: cardInfo.expiration_month,
        cardExpirationYear: cardInfo.expiration_year,
      };
      addExistingUserNewCard(data);
      onNext();
    } else if (!userExist) {
      onNext();
    }
  };

  const handleBack = () => {
    addCard({});
    onBack();
  };

  useEffect(() => {
    if (Object.keys(cardInfo).length !== 0) {
      submitInfo();
    }
  }, [cardInfo]);
  const inputWidth = isMobile ? '89.41vw' : '40.98vw';

  return (
    <div className={styles.container}>
      <Dialog
        fullScreen
        open={Gatewayopen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              Add New Card
            </Typography>
          </Toolbar>
        </AppBar>
        {newOlsUrl ? (
          <iframe
            title="payment"
            id="payment"
            src={`${newOlsUrl?.data}&parentUrl=${window.location.href}`}
            width="700px"
            style={{
              display: 'block',
              alignSelf: 'center',
              overflow: 'hidden',
              position: 'relative',
              marginLeft: '-20px',
              marginTop: '40px',
            }}
            scrolling="no"
            height={document.documentElement.clientHeight - 50}
          />
        ) : (
          <div className={classes.root}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          </div>
        )}
      </Dialog>
      <div className={`${styles.form1} ${isMobile ? styles.mobile : ''}`}>
        <div className={`${styles.inputWrapper} ${isMobile ? styles.mobile : ''}`}>
          <p className={styles.label}>
            Name on Card
            <Field
              value={billingName}
              onChange={e => setbillingName(e.target.value)}
              width={inputWidth}
              className={styles.input}
            />
          </p>
        </div>
        <div className={`${styles.inputWrapper} ${isMobile ? styles.mobile : ''}`}>
          <p className={styles.label}>
            Card Number
            <Field
              value={
                cardInfo?.cc_number
                  ? `**** **** **** ${cardInfo?.cc_number?.slice(-4)}`
                  : null
              }
              disabled={true}
              width={inputWidth}
              className={styles.input}
              rightIcon={imgDiscover}
              iconWidth={'40px'}
              iconTop={'13px'}
            />
          </p>
        </div>
        <div className={`${styles.inputWrapper} ${isMobile ? styles.mobile : ''}`}>
          <p className={styles.label}>
            Expiration Date
            <Field
              value={
                cardInfo?.expiration_month
                  ? cardInfo.expiration_month + '/' + cardInfo?.expiration_year
                  : null
              }
              disabled={true}
              width={inputWidth}
              className={styles.input}
            />
          </p>
        </div>
      </div>
      <div className={styles.scroll} />
      <BackAndNextButton
        backButtonName="Back"
        nextButtonName="Next"
        nextButtonDisable={
          (userExist && billingName === '') || billingName === null ? true : false
        }
        onBack={() => handleBack()}
        onNext={() => handleNext()}
      />
    </div>
  );
};

AddPayment.propTypes = {
  plan: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  loadNewOlsBillingUrl: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default AddPayment;
