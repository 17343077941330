/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const styleTypes = {
  text: styles.text,
  tertiary: styles.tertiary,
};

const Button = ({
  type,
  styleType,
  color,
  children,
  width,
  className,
  isRequesting,
  loaderStyles,
  disabled,
  ...props
}) => {
  const classNames = `${styles.base} ${styleTypes[styleType]} ${styles[color]} ${className}`;

  return (
    <button
      disabled={disabled}
      type={type}
      className={classNames}
      style={{ width }}
      {...props}
    >
      <div className={styles.content}>
        {isRequesting && (
          <div className={styles.ldsRing} style={loaderStyles}>
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
        <div>{children}</div>
      </div>
    </button>
  );
};

Button.propTypes = {
  styleType: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  isRequesting: PropTypes.bool,
  loaderStyles: PropTypes.object,
};

Button.defaultProps = {
  disabled: false,
  styleType: '',
  color: '',
  children: '',
  type: 'button',
  width: '',
  className: '',
  isRequesting: false,
  loaderStyles: {},
};

export default Button;
